<template>
	<section class="main-container">
		<div class="grid-2-1">
			<h1>Tableau de bord</h1>
			<div class="">
				<a href="#" class="refresh d-flex justify-content-end align-items-center" @click.prevent="getPersons()">
					<i class="fa fa-refresh"></i>
					<span class="ml-1 sm-hide">Actualiser</span>
				</a>
			</div>
		</div>
		<div class="grid-1-2 sm-grid-1 mb-2 align-items-center">
			<h4 class="text-primary">Statistiques globales</h4>
			<div class="grid-1-2 sm-grid-1">
				<div class="form-group">
					<label for="period">Période</label>
					<select class="form-control w-100" id="period" v-model="globalStatPeriod" @change="loadStats()">
						<option value="all">Toutes les périodes</option>
						<option value="today">Aujourd'hui</option>
						<option value="currentMonth">Ce mois</option>
						<option value="30days">30 derniers jours</option>
						<option value="90days">90 derniers jours</option>
						<option value="choose">Personnaliser</option>
					</select>
				</div>
				<div class="grid-1-1" v-if="globalStatPeriod == 'choose'">
					<div class="form-group">
						<label for="from">
							Du
						</label>
						<input type="date" id="from" class="form-control" v-model="from">
					</div>
					<div class="form-group">
						<label for="to">
							Au
						</label>
						<input type="date" id="to" class="form-control" v-model="to" @change="getPersons()">
					</div>
				</div>
			</div>
		</div>
		<div class=" grid-1-1-1-1 sm-grid-1-1">
			<div class="card text-white bg-success mb-3">
				<div class="card-header upper bold">Total</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.total}}</h5>
					<p class="card-text">Personnes enregistrées</p>
				</div>
			</div>

			<div class="card text-white bg-primary mb-3">
				<div class="card-header upper bold">Hommes</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.men}}</h5>
					<p class="card-text" v-if="stats.total > 0">Soit {{stats.menRate}}%</p>
				</div>
			</div>
			
			<div class="card text-white bg-danger mb-3">
				<div class="card-header upper bold">Femmes</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.women}}</h5>
					<p class="card-text" v-if="stats.total > 0">Soit {{stats.womenRate}}%</p>
				</div>
			</div>
			
			<div class="card text-white bg-warning mb-3">
				<div class="card-header upper bold">Mineurs</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.young}}</h5>
					<p class="card-text" v-if="stats.total > 0">Soit {{stats.youngRate}}% (des deux sexes)</p>
				</div>
			</div>
		</div>
		<br>
		<div class="grid-2-1 sm-grid-1">
			<div class="">
				<div class="center d-flex justify-content-start">
					<h4 class="mr-1 sm-hide text-primary">
						{{weekStats.men + weekStats.women}}
						Demandes
					</h4>
					<select class="form-control w-100 ml-1" v-model="whatWeek" style="width:200px !important;" @change="getCurrentWeekDays()">
						<option value="now">Cette semaine</option>
						<option value="previous">Semaine dernière</option>
					</select>
				</div>
				<ve-line :data="weekData" class="mt-2"></ve-line>
			</div>
			<div class="">
				<div class="center d-flex justify-content-start">
					<h4 class="mr-1 sm-hide text-primary">
						Proportions
					</h4>
					<select class="form-control w-100 ml-1" v-model="whatWeek" style="width:200px !important;" @change="getCurrentWeekDays()">
						<option value="now">Cette semaine</option>
						<option value="previous">Semaine dernière</option>
					</select>
				</div>
				<ve-pie :data="weekProportionData" class="mt-2"></ve-pie>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from 'axios'
	import VeLine from 'v-charts/lib/line.common'
	import VePie from 'v-charts/lib/pie.common'
	export default {
		name: 'Home',
		components: { VeLine, VePie },
		data () {
			return {
				globalStatPeriod: 'all',
				from: null,
				to: null,
				whatWeek: 'now',
				weekDays: null,
				weekDaysFr: null,
				persons: [],
				search: {
					reference: "",
					number: "",
					name: "",
					phone: "",
					email: "",
					datemin: null,
					datemax: null
				},
				meta: {
				  "page": {
				    "previous": 0,
				    "current": 0,
				    "next": 0,
				    "last": 0
				  },
				  "items": {
				    "current": 0,
				    "total": 0
				  }
				},
				weekStats: {
					"men": 0,
					"women": 0,
					"young": 0
				},
				stats: {
					"total": 0,
					"men": 0,
					"menRate": 0,
					"women": 0,
					"womenRate": 0,
					"young": 0,
					"youngRate": 0,
					"stats": [
						{
							"date": "13-02-2022",
							"count": 0
						}
					]
				},
				weekData: {
					columns: ['date', 'Hommes', 'Femmes', 'Mineurs'],
					backgroundColors: ['#007bff', '#dc3545', '#ffc107'],
			        rows: [
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 },
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 },
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 },
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 },
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 },
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 },
				        { 'date': null, 'Hommes': 0, 'Femmes': 0, 'Mineurs': 0 }
			        ]
				},
				weekProportionData: {
					columns: ['genre', 'Demandes'],
					colors: ['#007bff', '#dc3545'],
					rows: [
						{ 'genre': 'Hommes', 'Demandes': 0 },
						{ 'genre': 'Femmes', 'Demandes': 0 },
						//{ 'genre': 'Mineurs', 'Demandes': 0 }
					]
				},
		      	counter: 0,
		      	successIds: [],
		      	errorIds: [],
		      	minorDate: null,
			}
		},
		methods: {
			getMinorThresholdDate() {
		      const today = new Date();
		      // Calculate the date 18 years ago from tomorrow (to include today as minor)
		      const minorThresholdDate = new Date(today);
		      minorThresholdDate.setFullYear(minorThresholdDate.getFullYear() - 18);
		      minorThresholdDate.setDate(minorThresholdDate.getDate() + 1); // Adjust to include today

		      let year = minorThresholdDate.getFullYear();
		      let month = String(minorThresholdDate.getMonth() + 1).padStart(2, '0');
		      let day = String(minorThresholdDate.getDate()).padStart(2, '0');

		      this.minorDate = `${year}-${month}-${day}`;
		    },
			getCurrentWeekDays() {
		      const today = new Date();
		      // Find the first day of the week (Monday)
		      let firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));


		      // Find the first day of the previous week (Monday)
  				let firstDayOfPreviousWeek = new Date(firstDayOfWeek);
  					firstDayOfPreviousWeek.setDate(firstDayOfPreviousWeek.getDate() - 7);

  				if(this.whatWeek == 'previous')
  					firstDayOfWeek = firstDayOfPreviousWeek

		      let days = [];
		      for (let i = 0; i < 7; i++) {
		        let currentDay = new Date(firstDayOfWeek);
		        currentDay.setDate(currentDay.getDate() + i);
		        let year = currentDay.getFullYear();
		        let month = String(currentDay.getMonth() + 1).padStart(2, '0');
		        let day = String(currentDay.getDate()).padStart(2, '0');
		        days.push(`${year}-${month}-${day}`);
		      }

		      this.weekDays = days

		      for (let j = 0; j < 7; j++) {
		        //this.weekData.rows[j].date = this.weekDays[j]
		        this.weekData.rows[j].date = this.$root.literateDate(this.$root.frDate(this.weekDays[j]), true)
		        //this.weekDaysFr[j] = this.literateDate(this.weekDays[j], true)

		        this.getPersonsGender('m', this.weekDays[j])
		        this.getPersonsGender('f', this.weekDays[j])
		        this.getPersonsMineur(this.weekDays[j])
		      }

		    },
		    calculateProportion(){
		    	let total = this.weekStats.men + this.weekStats.women

		    	// proportion d'hommes
		    	this.weekProportionData.rows[0].Demandes = this.weekStats.men

		    	// proportion de femmes
		    	this.weekProportionData.rows[1].Demandes = this.weekStats.women

		    	// proportion de mineurs
		    	//this.weekProportionData.rows[2].Demandes = this.weekStats.young

		    	/*// proportion d'hommes
		    	this.weekProportionData.rows[0].Demandes = ((this.weekStats.men / total)*100).toFixed(2)

		    	// proportion de femmes
		    	this.weekProportionData.rows[1].Demandes = ((this.weekStats.women / total)*100).toFixed(2)

		    	// proportion de mineurs
		    	this.weekProportionData.rows[2].Demandes = ((this.weekStats.young / total)*100).toFixed(2)*/
		    },
			getStats() {
				let vm = this
				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'dashboard',
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.stats = r.data.data
						
						this.pieData.rows[0].Immatriculations = this.stats.men
						this.pieData.rows[1].Immatriculations = this.stats.women
						this.pieData.rows[2].Immatriculations = this.stats.young

						for(let i=0; i<this.stats.stats.length; i++){
							this.chartData.rows.push({ 'date': this.stats.stats[i].date, 'Immatriculations': this.stats.stats[i].count })
						}

					}else{
						console.log(r.data.message)
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
			},
			loadStats() {
			  const today = new Date()
			  const formatDate = (date) => date.toISOString().split('T')[0]

			  switch (this.globalStatPeriod) {
			    case 'all':
			      this.from = "1970-01-01"
			      this.to = formatDate(today)
			      this.getPersons()
			      break;
			    case 'today':
			      this.from = formatDate(today)
			      this.to = this.from
			      this.getPersons()
			      break;
			    case 'currentMonth':
			      this.from = formatDate(new Date(today.getFullYear(), today.getMonth(), 2))
			      this.to = formatDate(today)
			      this.getPersons()
			      break;
			    case '30days':
			      this.to = formatDate(today)
			      this.from = formatDate(new Date(today.setDate(today.getDate() - 30)))
			      this.getPersons()
			      break;
			    case '90days':
			      this.to = formatDate(new Date())
			      this.from = formatDate(new Date(today.setDate(today.getDate() - 90)))
			      this.getPersons()
			      break;
			    case 'choose':
			      this.to = null
			      this.from = null
			      break;
			  }

			},
			createAccount(){
				axios({
					method: 'POST',
					url: 'agent',
					headers: this.$root.authHeader(),
					data: {
						'lastname' : 'KONATÉ',
						'firstname' : 'Issiaka',
						'email' : 'issiaka.konate@icloud.com',
						'password' : 'qtaSHRem0',
						'position' : 'Ambassadeur - Consul Général',
						'phone' : '0665208329'
					},
					responseType: 'json'
				})
				.then(r => {
					console.log(r)
				})
			},
			getPersons () {
				let vm = this

				this.search.datemin = this.from
				this.search.datemax = this.to

				let datas = ""

				if(this.search.reference != "")
					datas += "reference="+this.search.reference

				if(this.search.number != "")
					datas += "number="+this.search.number

				if(this.search.name != "")
					datas += "&name="+this.search.name

				if(this.search.phone != "")
					datas += "&phone="+this.search.phone

				if(this.search.email != "")
					datas += "&email="+this.search.email

				if(this.search.datemin != null)
					datas += "&datemin="+this.search.datemin

				if(this.search.datemax != null)
					datas += "&datemax="+this.search.datemax

				datas += "&limit=100"

				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Chargement des statistiques...', 'info')
						return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'app/persons?'+datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.persons = r.data.data.items
						this.meta = r.data.data.meta

						this.stats.total = this.meta.items.total

						if(this.from || this.to){
							this.getPersonsGenderFromTo('m', this.from, this.to)
							this.getPersonsGenderFromTo('f', this.from, this.to)
							this.getPersonsMineurFromTo(this.from, this.to)
						}
						else{
							this.getPersonsGender('m')
							this.getPersonsGender('f')
							this.getPersonsMineur()
						}

						this.$swal.close()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			},
			getPersonsGenderFromTo (g, from, to) {
				let vm = this

				let datas = ""

				datas += "gender="+g

				datas += "&limit=100"

				if(from || to){
					datas += "&datemin="+from
					datas += (to) ? "&datemax="+to : "&datemax="+from
				}

				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'app/persons?'+datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let meta = r.data.data.meta

						if(g == 'm'){
							this.stats.men = meta.items.total
						}
						else{
							this.stats.women = meta.items.total
						}

						this.calculateProportion()
						this.updateRates()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			},
			getPersonsGender (g, d) {
				let vm = this

				let datas = ""

				datas += "gender="+g

				datas += "&limit=100"

				if(d){
					datas += "&datemin="+d
					datas += "&datemax="+d
				}

				/*this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Chargement des statistiques...', 'info')
						return config
				}, (error) => {
					return Promise.reject(error)
				})*/

				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'app/persons?'+datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.persons = r.data.data.items
						this.meta = r.data.data.meta


						if(d){

							if(g == 'm'){

								this.weekData.rows[this.weekDays.indexOf(d)].Hommes = this.meta.items.total

								this.weekStats.men += this.weekData.rows[this.weekDays.indexOf(d)].Hommes
							}
							else{
								this.weekData.rows[this.weekDays.indexOf(d)].Femmes = this.meta.items.total

								this.weekStats.women += this.weekData.rows[this.weekDays.indexOf(d)].Femmes
							}
						} else {

							if(g == 'm'){
								this.stats.men = this.meta.items.total
							}
							else{
								this.stats.women = this.meta.items.total
							}
						}

						this.$swal.close()

						this.calculateProportion()
						this.updateRates()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			},
			getPersonsMineurFromTo (from, to) {
				let vm = this

				let datas = ""

				datas += "birthdate="+this.minorDate

				datas += "&limit=100"

				if(from || to){
					datas += "&datemin="+from
					datas += (to) ? "&datemax="+to : "&datemax="+from
				}

				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'app/persons?'+datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let meta = r.data.data.meta

						this.stats.young = meta.items.total

						this.calculateProportion()
						this.updateRates()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			},
			getPersonsMineur (d) {
				let vm = this

				let datas = ""

				datas += "birthdate="+this.minorDate

				datas += "&limit=100"

				if(d){
					datas += "&datemin="+d
					datas += "&datemax="+d
				}

				/*this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Chargement des statistiques...', 'info')
						return config
				}, (error) => {
					return Promise.reject(error)
				})*/

				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'app/persons?'+datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.persons = r.data.data.items
						this.meta = r.data.data.meta

						if(!d){
							this.stats.young = this.meta.items.total
						}

						this.weekData.rows[this.weekDays.indexOf(d)].Mineurs = this.meta.items.total

						this.weekStats.young += this.weekData.rows[this.weekDays.indexOf(d)].Mineurs

						this.$swal.close()

						this.calculateProportion()
						this.updateRates()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			},
			updateRates(){
				this.stats.menRate = ((this.stats.men/this.stats.total)*100).toFixed(2)
				this.stats.womenRate = ((this.stats.women/this.stats.total)*100).toFixed(2)
				this.stats.youngRate = ((this.stats.young/this.stats.total)*100).toFixed(2)
			}

		},
		created () {
			this.getMinorThresholdDate()
			this.getCurrentWeekDays()
			//this.getStats()
			//this.createAccount()
			this.getPersons()
		}
	}
</script>
