<template>
	<section class="main-container">
		<h1 v-if="uid">
			<a :href="'/immatriculation/'+individu.id" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left mr-1"></i>
				Retour
			</a>
			&nbsp;
			Modifier un enregistrement
			<br><br>
			<small class="text-primary">{{individu.firstname}} {{individu.lastname}}</small>
		</h1>
		<h1 v-else>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left mr-1"></i>
				Demandes
			</router-link>
			&nbsp;
			<i class="fa fa-address-card-o"></i>
			Nouvelle demande
		</h1>
		
		<div class="d-flex justify-content-between align-items-center">
			<div class="alert alert-info">
				Les champs marqués par une étoile (*) sont obligatoires.
			</div>

			<a href="" class="reset_form" @click.prevent="resetForm()">Réinitialiser le formulaire</a>

		</div>

		<div class="aic_popup hide">
			<div class="center">
				<div id="for_photo" v-if="popupFor == 'photo'">
					<h4>Ajouter la photo d'identité</h4>
					
					<input class="selectFile" type="file" ref="photo" @change="loadImage($event, 'photo')" accept="image/*" capture="camera">
					<button id="btnPhoto" class="button btn btn-outline-primary" @click.prevent="$refs.photo.click()">
						Sélectionner une photo
					</button>
					<br><br>
					<cropper
						ref="photoCropper"
						class="upload-example-cropper"
						:src="photo.src"
						:stencil-props="{
							aspectRatio: 0.78,
						}"
					/>
					<br>
					<div class="button-wrapper">
						<button class="button btn btn-primary" @click.prevent="instanciateFile('photo')">
							Valider
						</button>
					</div>
				</div>
				<div id="for_signature" v-if="popupFor == 'signature'">
					<h4>Ajouter la signature</h4>

					<input class="selectFile" type="file" ref="signature" @change="loadImage($event, 'signature')" accept="image/*">
					<button id="btnSignature" class="button btn btn-outline-primary" @click.prevent="$refs.signature.click()">
						Sélectionner une signature
					</button>
					<br><br>
					<cropper
						ref="signatureCropper"
						class="upload-example-cropper wide"
						:src="signature.src"
						:stencil-props="{
							
						}"
					/>
					<br>
					<div class="button-wrapper">
						<button class="button btn btn-primary" @click.prevent="instanciateFile('signature')">
							Valider
						</button>
					</div>
				</div>
				<div id="for_dependants" v-if="popupFor == 'dependents'">
					<form>
						<h4>Ajouter un enfant à charge</h4>

						<div class="row">
							<div class="form-group col-6">
								<label for="">Nom</label>
								<input type="text" id="" class="form-control upper" v-model="dependent.lastname">
							</div>
							<div class="form-group col-6">
								<label for="">Prénoms</label>
								<input type="text" id="" class="form-control capitalize" v-model="dependent.firstname">
							</div>
						</div>
						<div class="row">
							<div class="form-group col-6">
								<label for="">Date de naissance</label>
								<input type="date" id="" class="form-control" v-model="dependent.date_of_birth">
							</div>
							<div class="form-group col-6">
								<label for="">Lieu de naissance</label>
								<input type="text" id="" class="form-control capitalize" v-model="dependent.place_of_birth">
							</div>
						</div>

						<br>
						<div class="button-wrapper d-flex justify-content-between align-items-center">
							<input type="submit" class="button btn btn-primary" @click.prevent="addDependent()" value="Valider">

							<button class="btn btn-outline-secondary" @click.prevent="closePopup()">Annuler</button>
						</div>
					</form>
				</div>
				<div id="for_marginal_notes" v-if="popupFor == 'marginal_notes'">
					<form>
						<h4>Ajouter une mention marginale</h4>

						<div class="row">
							<div class="form-group col-6">
								<label for="">Événement</label>
								<select class="form-control" v-model="marginal_note.event">
									<option value=null>Sélectionnez</option>
									<option value="married">Marié(e)</option>
									<option value="separated">Séparé(e)</option>
									<option value="divorced">Divorcé(e)</option>
									<option value="widowed">Veuf(ve)</option>
								</select>
							</div>
							<div class="form-group col-6">
								<label for="">Date de l'événement</label>
								<input type="date" id="" class="form-control" v-model="marginal_note.event_date">
							</div>
						</div>
						<div class="row">
							<div class="form-group col-12">
								<label for="">Nom complet du(de la) conjoint(e)</label>
								<input type="text" id="" class="form-control capitalize" v-model="marginal_note.spouse_name">
							</div>
						</div>

						<br>
						<div class="button-wrapper d-flex justify-content-between align-items-center">
							<input type="submit" class="button btn btn-primary" @click.prevent="addMarginaleNote()" value="Valider" />

							<button class="btn btn-outline-secondary" @click.prevent="closePopup()">Annuler</button>
						</div>
					</form>
				</div>
				<button class="btn btn-outline-secondary topup" @click.prevent="closePopup()">Annuler</button>
			</div>
		</div>

		<form method="post" enctype="multipart/form-data" v-if="!individu.is_canceled">
			<!--fieldset>
				<legend>Référence Podio</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="external_reference">Numéro de référence *</label>
						<input type="text" class="form-control upper" id="external_reference" v-model="individu.external_reference" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset-->
			<fieldset>
				<legend>Informations personnelles</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="gender">Genre *</label>
						<select class="form-control" id="gender" v-model="individu.gender" @change="updateLocalIndividu()">
							<option value="m">Homme</option>
							<option value="f">Femme</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="lastname">Nom *</label>
						<input type="text" class="form-control upper" id="lastname" placeholder="" v-model="individu.lastname" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="firstname">Prénoms *</label>
						<input type="text" class="form-control capitalize" id="firstname" placeholder="" v-model="individu.firstname" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="date_of_birth">Date de naissance *</label>
						<input type="date" class="form-control" id="date_of_birth" v-model="individu.date_of_birth" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="place_of_birth">Lieu de naissance *</label>
						<input type="text" class="form-control capitalize" id="place_of_birth" v-model="individu.place_of_birth" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="nationality">Nationalité *</label>
						<input type="text" class="form-control" id="nationality" v-model="individu.nationality" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Contacts</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="adresse_adresse">Adresse *</label>
						<input type="text" class="form-control" required id="adresse_adresse" v-model="individu.address" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="phone">Téléphone *</label>
						<input type="phone" class="form-control" required id="phone" v-model="individu.phone" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="email">Email *</label>
						<input type="email" class="form-control" id="email" required v-model="individu.email" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Profession *</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="occupation">Activité professionnelle</label>
						<input type="text" class="form-control" required id="occupation" v-model="individu.occupation" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset v-if="individu.id == null">
				<legend>Photo & Signature</legend>
				<div class="row">
					<div class="form-group col-8">
						<div class="row">
							<div class="form-group col-6">
								<label for="photo" @click="openPopup('photo')" class="btn btn-label">
									Ajouter une photo
								</label>
								<br><br>
								<div class="preview_picture">
									<img :src="photoPreview" alt="" class="photoPreview">
								</div>
							</div>
							<div class="form-group col-6">
								<label for="signature" @click="openPopup('signature')" class="btn btn-label">
									Ajouter une signature
								</label>
								<br><br>
								<div class="preview_signature">
									<img :src="signaturePreview" alt="" class="photoPreview">
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Père</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="father_full_name">Nom complet</label>
						<input type="text" class="form-control upper" id="father_full_name" v-model="individu.parents.father_full_name" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="father_place_of_birth">Lieu de naissance</label>
						<input type="text" class="form-control" id="father_place_of_birth" v-model="individu.parents.father_place_of_birth" @change="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="father_address_in_Ivory_Coast">Adresse en Côte d'Ivoire</label>
						<input type="text" class="form-control capitalize" id="father_address_in_Ivory_Coast" v-model="individu.parents.father_address_in_Ivory_Coast" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="father_occupation">Profession</label>
						<input type="text" class="form-control" id="father_occupation" v-model="individu.parents.father_occupation" @change="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>
			<fieldset>
				<legend>Mère</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="mother_full_name">Nom complet</label>
						<input type="text" class="form-control upper" id="mother_full_name" v-model="individu.parents.mother_full_name" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mother_place_of_birth">Lieu de naissance</label>
						<input type="text" class="form-control" id="mother_place_of_birth" v-model="individu.parents.mother_place_of_birth" @change="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mother_address_in_Ivory_Coast">Adresse en Côte d'Ivoire</label>
						<input type="text" class="form-control capitalize" id="mother_address_in_Ivory_Coast" v-model="individu.parents.mother_address_in_Ivory_Coast" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mother_occupation">Profession</label>
						<input type="text" class="form-control" id="mother_occupation" v-model="individu.parents.mother_occupation" @change="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Situation de famille</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="marital_status">Situation matrimoniale</label>
						<select class="form-control" id="marital_status" v-model="individu.marital_status" @change="updateLocalIndividu()">
							<option value=null>Sélectionnez</option>
							<option value="married">Marié(e)</option>
							<option value="divorced">Divorcé(e)</option>
							<option value="single">Célibataire</option>
							<option value="widowed">Veuf(ve)</option>
							<option value="separated">Séparé(e)</option>
						</select>
					</div>
				</div>
				<h5 class="d-flex justify-content-between align-items-center mb-2">
					Enfants à charge
					<button class="btn btn-info" @click.prevent="openPopup('dependents')">
						<i class="fa fa-plus"></i>&nbsp;
						Ajouter
					</button>
				</h5>
				<br>
				<div id="family_members" v-if="individu.dependents.items.length > 0">
					<div class="row d-flex align-items-center" v-for="(v, k) in individu.dependents.items" :key="k">
						<div class="form-group col-3">
							<label>Nom</label>
							<input type="text" class="form-control upper" v-model="individu.dependents.items[k].lastname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label>Prénoms</label>
							<input type="text" class="form-control capitalize" v-model="individu.dependents.items[k].firstname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-2">
							<label>Date de naissance</label>
							<input type="date" class="form-control" v-model="individu.dependents.items[k].date_of_birth" @change="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label>Lieu de naissance</label>
							<input type="text" class="form-control capitalize" v-model="individu.dependents.items[k].place_of_birth" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-1">
							<label class="d-block">&nbsp;</label>
							<button class="btn" @click.prevent="deleteDependent(k)">
								<i class="fa fa-trash"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="alert alert-warning" v-else>Aucun enfant ajouté</div>
				<br>
				<h5 class="d-flex justify-content-between align-items-center mb-2">
					Mentions marginales
					<button class="btn btn-info" @click.prevent="openPopup('marginal_notes')">
						<i class="fa fa-plus"></i>&nbsp;
						Ajouter
					</button>
				</h5>
				<br>
				<div id="marginale_notes" v-if="individu.marginal_notes.items.length > 0">
					<div class="row" v-for="(v, k) in individu.marginal_notes.items" :key="k">
						<div class="form-group col-3">
							<label>Événement</label>
							<select class="form-control" v-model="individu.marginal_notes.items[k].event" @change="updateLocalIndividu()">
								<option value=null>Sélectionnez</option>
								<option value="married">Marié(e)</option>
								<option value="separated">Séparé(e)</option>
								<option value="divorced">Divorcé(e)</option>
								<option value="widowed">Veuf(ve)</option>
							</select>
						</div>
						<div class="form-group col-3">
							<label>Date de naissance</label>
							<input type="date" class="form-control" v-model="individu.marginal_notes.items[k].event_date" @change="updateLocalIndividu()">
						</div>
						<div class="form-group col-5">
							<label>Nom complet du(de la) conjoint(e)</label>
							<input type="text" class="form-control capitalize" v-model="individu.marginal_notes.items[k].spouse_name" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-1">
							<label class="d-block">&nbsp;</label>
							<button class="btn" @click.prevent="deleteMarginaleNote(k)">
								<i class="fa fa-trash"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="alert alert-warning" v-else>Aucune mention marginale ajoutée</div>
			</fieldset>

			<div v-if="individu.contact_persons.items.length > 0" v-for="c in individu.contact_persons.items">
				<fieldset v-if="c.country == 'fr'">
					<legend>Personne à contacter en France</legend>
					<div class="row">
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_nom">Nom</label>
							<input type="text" class="form-control upper" id="personnes_a_prevenir_fr_nom" v-model="c.lastname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_prenoms">Prénoms</label>
							<input type="text" class="form-control capitalize" id="personnes_a_prevenir_fr_prenoms" v-model="c.firstname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_adresse_adresse">Adresse</label>
							<input type="text" class="form-control" id="personnes_a_prevenir_fr_adresse_adresse" v-model="c.address" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_telephone">Téléphone</label>
							<input type="phone" class="form-control" id="personnes_a_prevenir_fr_telephone" v-model="c.phone" @blur="updateLocalIndividu()">
						</div>
					</div>
				</fieldset>
				<fieldset v-if="c.country == 'ci'">
					<legend>Personne à contacter en Côte d'Ivoire</legend>
					<div class="row">
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_nom">Nom</label>
							<input type="text" class="form-control upper" id="personnes_a_prevenir_ci_nom" v-model="c.lastname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_prenoms">Prénoms</label>
							<input type="text" class="form-control capitalize" id="personnes_a_prevenir_ci_prenoms" v-model="c.firstname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_adresse_adresse">Adresse</label>
							<input type="text" class="form-control" id="personnes_a_prevenir_ci_adresse_adresse" v-model="c.address" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_telephone">Téléphone</label>
							<input type="phone" class="form-control" id="personnes_a_prevenir_ci_telephone" v-model="c.phone" @blur="updateLocalIndividu()">
						</div>
					</div>
				</fieldset>
			</div>
			<div v-else-if="individu.contact_persons.items.length == 0">
				<fieldset>
					<legend>Personne à contacter en France</legend>
					<div class="row">
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_nom">Nom</label>
							<input type="text" class="form-control upper" id="personnes_a_prevenir_fr_nom" v-model="individu.contact_persons.items[0].lastname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_prenoms">Prénoms</label>
							<input type="text" class="form-control capitalize" id="personnes_a_prevenir_fr_prenoms" v-model="individu.contact_persons.items[0].firstname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_adresse_adresse">Adresse</label>
							<input type="text" class="form-control" id="personnes_a_prevenir_fr_adresse_adresse" v-model="individu.contact_persons.items[0].address" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_fr_telephone">Téléphone</label>
							<input type="phone" class="form-control" id="personnes_a_prevenir_fr_telephone" v-model="individu.contact_persons.items[0].phone" @blur="updateLocalIndividu()">
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend>Personne à contacter en Côte d'Ivoire</legend>
					<div class="row">
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_nom">Nom</label>
							<input type="text" class="form-control upper" id="personnes_a_prevenir_ci_nom" v-model="individu.contact_persons.items[1].lastname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_prenoms">Prénoms</label>
							<input type="text" class="form-control capitalize" id="personnes_a_prevenir_ci_prenoms" v-model="individu.contact_persons.items[1].firstname" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_adresse_adresse">Adresse</label>
							<input type="text" class="form-control" id="personnes_a_prevenir_ci_adresse_adresse" v-model="individu.contact_persons.items[1].address" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="personnes_a_prevenir_ci_telephone">Téléphone</label>
							<input type="phone" class="form-control" id="personnes_a_prevenir_ci_telephone" v-model="individu.contact_persons.items[1].phone" @blur="updateLocalIndividu()">
						</div>
					</div>
				</fieldset>
			</div>
			<br>
			<div class="form-group">
				<button class="btn btn-primary" @click.prevent="saveData()">
					<i class="fa fa-floppy-o mr-1"></i>
					Enregistrer
				</button>
			</div>
		</form>
		<div v-else>
			<div class="alert alert-danger">
				Immatriculation annulée
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import States from "./usa_states.json"
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
	name: 'CreateAccount',
	components: { Cropper },
	data () {
		return {
			photo: {
				src: null,
				type: null
			},
			signature: {
				src: null,
				type: null
			},
			popupFor: 'photo',
			photoPreview: null,
			signaturePreview: null,
			photo_: null,
			signature_: null,
			person: null,
			uid: null,
			individu: (localStorage.getItem('individu') != 'undefined') ? JSON.parse(localStorage.getItem('individu')) : {
			    "id": null,
			    "email": null,
			    "lastname": null,
			    "firstname": null,
			    "date_of_birth": null,
			    "phone": null,
			    "occupation": null,
			    "address": null,
			    "place_of_birth": null,
			    "gender": "m",
			    "nationality": null,
			    "photo": null,
			    "signature": null,
			    "marital_status": null,
			    "registration_number": null,
			    "card_url": null,
			    "address_in_ivory_coast": null,
			    "card_generation_date": null,
			    "next_card_generation_date": null,
			    "external_reference": null,
			    "marginal_notes": {
			        "items": []
			    },
			    "contact_persons": {
			        "items": [
			        	{
			        		country: 'fr',
			        		lastname: null,
			        		firstname: null,
			        		address: null,
			        		phone: null,
			        	},
			        	{
			        		country: 'ci',
			        		lastname: null,
			        		firstname: null,
			        		address: null,
			        		phone: null,
			        	}
			        ]
			    },
			    "parents": {
			        "father_full_name": null,
			        "father_place_of_birth": null,
			        "father_address_in_Ivory_Coast": null,
			        "father_occupation": null,
			        "mother_full_name": null,
			        "mother_place_of_birth": null,
			        "mother_address_in_Ivory_Coast": null,
			        "mother_occupation": null
			    },
			    "dependents": {
			        "items": []
			    },
			    "documents": {
			        "items": []
			    }
			},
			countFamilyMembers: 1,
			dependent: {
        		lastname: null,
        		firstname: null,
        		date_of_birth: null,
        		place_of_birth: null
    		},
			marginal_note: {
        		event: null,
        		event_date: null,
        		spouse_name: null
    		}
		}
	},
	methods: {
		closePopup(){
			document.querySelector('.aic_popup').classList.add("hide")
		},
		openPopup(t){
			document.querySelector('.aic_popup').classList.remove("hide")
			this.popupFor = t
			this.reset(t)
		},
		addMarginaleNote(){
			if(this.marginal_note.event == null){
				this.$swal('Événement', "Vous devez saisir un nom valide", 'warning')

			} else if(this.marginal_note.event_date == null){
				this.$swal('Date', "Vous devez sélectionner la date de l'événement", 'warning')

			} else if((this.marginal_note.spouse_name == null || this.marginal_note.spouse_name.length < 2)){
				this.$swal('Nom', "Vous devez saisir un nom valide", 'warning')

			} else{
				this.individu.marginal_notes.items.push(this.marginal_note)
				this.updateLocalIndividu()
				this.closePopup()
			}
		},
		addDependent(){
			if(this.dependent.lastname == null || this.dependent.lastname.length < 2){
				this.$swal('Nom', "Vous devez saisir un nom valide", 'warning')

			} else if(this.dependent.firstname == null || this.dependent.firstname.length < 2){
				this.$swal('Prénom', "Vous devez saisir un prénom valide", 'warning')

			} else if(this.dependent.date_of_birth == null){
				this.$swal('Date de naissance', "Vous devez sélectionner la date de naissance", 'warning')

			} else if(this.dependent.place_of_birth == null || this.dependent.place_of_birth.length < 2){
				this.$swal('Lieu de naissance', "Vous devez saisir un lieu de naissance valide", 'warning')

			} else{
				this.individu.dependents.items.push(this.dependent)
				this.dependent = {
	        		lastname: null,
	        		firstname: null,
	        		date_of_birth: null,
	        		place_of_birth: null
	    		}
				this.updateLocalIndividu()
				this.closePopup()
			}
		},
	    deleteDependent(k){
	        this.individu.dependents.items.splice(k, 1)
			this.updateLocalIndividu()
	    },
	    deleteMarginaleNote(k){
	        this.individu.marginal_notes.items.splice(k, 1)
			this.updateLocalIndividu()
	    },
		addFamilyMember(){
			let nextMember = this.countFamilyMembers

			this.individu.dependents.items[nextMember] = {
			        		lastname: null,
			        		firstname: null,
			        		date_of_birth: null,
			        		place_of_birth: null,
		        		}

			let memberLine = '<div class="row" id="fm_'+nextMember+'">' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_nom">Nom</label>' +
									'<input type="text" class="form-control upper" id="fm_'+nextMember+'_nom" v-model="individu.dependents.items['+nextMember+'].lastname" @blur="updateLocalIndividu()">' +
								'</div>' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_prenoms">Prénoms</label>' +
									'<input type="text" class="form-control capitalize" id="fm_'+nextMember+'_prenoms" v-model="individu.dependents.items['+nextMember+'].firstname" @blur="updateLocalIndividu()">' +
								'</div>' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_date_naissance">Date de naissance</label>' +
									'<input type="date" class="form-control" id="fm_'+nextMember+'_date_naissance" v-model="individu.dependents.items['+nextMember+'].date_of_birth" @change="updateLocalIndividu()">' +
								'</div>' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_date_naissance">Lieu de naissance</label>' +
									'<input type="text" class="form-control capitalize" id="fm_'+nextMember+'_date_naissance" v-model="individu.dependents.items['+nextMember+'].place_of_birth" @blur="updateLocalIndividu()">' +
								'</div>' +
							'</div>'

			document.querySelector("#family_members").innerHTML += memberLine

			this.updateLocalIndividu()
		},
		instanciateFile(t) {
			const { canvas } = (t == 'photo') ? this.$refs.photoCropper.getResult() : this.$refs.signatureCropper.getResult()
			
			if (canvas) {
				let vm = this
				canvas.toBlob(blob => {
					const reader = new FileReader()

					switch(t){
						case('photo'): vm.photo_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.photoPreview = reader.result
								
							};
						break;
						case('signature'): vm.signature_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.signaturePreview = reader.result
								
							};
						break;
					}

				}, 'image/jpeg');

				document.querySelector('.aic_popup').classList.add("hide")
			}
		},
		reset(t) {
			if(t == 'photo'){
				this.photo = {
					src: null,
					type: null
				}
			}
			else if(t == 'signature'){
				this.signature = {
					src: null,
					type: null
				}
			}
			else if(t == 'dependent'){
				this.dependent = {
	        		lastname: null,
	        		firstname: null,
	        		date_of_birth: null,
	        		place_of_birth: null
	    		}
			}
		},
		loadImage(event, t) {

			const { files } = event.target

			if (files && files[0]) {

				const blob = URL.createObjectURL(files[0])
				const reader = new FileReader()

				switch(t){
					case('photo'):
						if (this.photo.src) {
							URL.revokeObjectURL(this.photo.src)
						}

						reader.onload = (e) => {

							this.photo = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
					case('signature'):
						if (this.signature.src) {
							URL.revokeObjectURL(this.signature.src)
						}

						reader.onload = (e) => {

							this.signature = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
				}

				reader.readAsArrayBuffer(files[0]);
			}
		},
		getImgData(files) {

			if (files) {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(files);
				fileReader.addEventListener("load", function () {
					return this.result
					//this.photo_ = this.result
					//console.log(this.result)
					//imgPreview.style.display = "block";
					//imgPreview.innerHTML = '<img src="' + this.result + '" />';
				});    
			}
		},
		choosePhoto(){
			this.photo_ = document.querySelector('#photo').files[0]
			console.log(this.getImgData(this.photo_))
			//console.log(this.photo_)
		},
		chooseSignature(){
			this.signature_ = document.querySelector('#signature').files[0]
		},
        resetForm(){
            this.individu = {
			    "id": null,
			    "email": null,
			    "lastname": null,
			    "firstname": null,
			    "date_of_birth": null,
			    "phone": null,
			    "occupation": null,
			    "address": null,
			    "place_of_birth": null,
			    "gender": "m",
			    "nationality": null,
			    "photo": null,
			    "signature": null,
			    "marital_status": null,
			    "registration_number": null,
			    "card_url": null,
			    "address_in_ivory_coast": null,
			    "card_generation_date": null,
			    "next_card_generation_date": null,
			    "external_reference": null,
			    "marginal_notes": {
			        "items": []
			    },
			    "contact_persons": {
			        "items": [
			        	{
			        		country: 'fr',
			        		lastname: null,
			        		firstname: null,
			        		address: null,
			        		phone: null
			        	},
			        	{
			        		country: 'ci',
			        		lastname: null,
			        		firstname: null,
			        		address: null,
			        		phone: null
			        	}
			        ]
			    },
			    "parents": {
			        "father_full_name": null,
			        "father_place_of_birth": null,
			        "father_address_in_Ivory_Coast": null,
			        "father_occupation": null,
			        "mother_full_name": null,
			        "mother_place_of_birth": null,
			        "mother_address_in_Ivory_Coast": null,
			        "mother_occupation": null
			    },
			    "dependents": {
			        "items": []
			    },
			    "documents": {
			        "items": []
			    }
			}

			this.photoPreview = null
			this.signaturePreview = null
			this.photo_ = null
			this.signature_ = null

            localStorage.setItem('individu', JSON.stringify(this.individu))
        },
        updateLocalIndividu() {
        	//console.log(this.individu.dependents)
        	
            if(!this.individu){
                this.individu = {
				    "id": null,
				    "email": null,
				    "lastname": null,
				    "firstname": null,
				    "date_of_birth": null,
				    "phone": null,
				    "occupation": null,
				    "address": null,
				    "place_of_birth": null,
				    "gender": "m",
				    "nationality": null,
				    "photo": null,
				    "signature": null,
				    "marital_status": null,
				    "registration_number": null,
				    "card_url": null,
				    "address_in_ivory_coast": null,
				    "card_generation_date": null,
				    "next_card_generation_date": null,
				    "external_reference": null,
				    "marginal_notes": {
				        "items": []
				    },
				    "contact_persons": {
				        "items": [
				        	{
				        		country: 'fr',
				        		lastname: null,
				        		firstname: null,
				        		address: null,
				        		phone: null
				        	},
				        	{
				        		country: 'ci',
				        		lastname: null,
				        		firstname: null,
				        		address: null,
				        		phone: null
				        	}
				        ]
				    },
				    "parents": {
				        "father_full_name": null,
				        "father_place_of_birth": null,
				        "father_address_in_Ivory_Coast": null,
				        "father_occupation": null,
				        "mother_full_name": null,
				        "mother_place_of_birth": null,
				        "mother_address_in_Ivory_Coast": null,
				        "mother_occupation": null
				    },
				    "dependents": {
				        "items": []
				    },
				    "documents": {
				        "items": []
				    }
				}
            }

          localStorage.setItem('individu', JSON.stringify(this.individu))
          
        },
        getDocuments(){

        },
		saveData () {
			let vm = this

			if (this.individu.lastname == null) {
				this.$swal('Nom', "Vous devez saisir le nom de l'individu.", 'warning')
				return !1

			} else if (this.individu.firstname == null) {
				this.$swal('Nom', "Vous devez saisir le prénom de l'individu.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let isNew = (this.uid == null) ? true : false

				// si on a un identifiant de personne, on utilise la terminaise de modification, sinon c'est un nouvel enregistrement
				let requestUrl = (isNew) ? 'app/person' : 'app/person/'+this.uid

				/* 
					Si on n'a pas d'identifiant (uid), alors c'est un nouvel enregistrement
					Dans ce cas, on utilise formData afin de pouvoir transmettre les fichiers
				*/
				let datas = (isNew) ? new FormData() : {}

				if(this.individu.external_reference == null || this.individu.external_reference == ""){
					let randomNumber = Math.floor(Math.random() * 1e10);

				    // On s'assure que le nombre a bien 10 chiffres en ajoutant des zéros devant si nécessaire
				    let random10DigitNumber = randomNumber.toString().padStart(10, '0');

					this.individu.external_reference = random10DigitNumber
				}

				if(isNew){ // préparation des données à envoyer avec formData
					datas.append('photo',this.photo_)
					datas.append('signature',this.signature_)
					datas.append('external_reference',this.individu.external_reference)
					datas.append('email',this.individu.email)
					datas.append('gender',this.individu.gender)
					datas.append('lastname',this.individu.lastname)
					datas.append('firstname',this.individu.firstname)
					datas.append('date_of_birth',this.individu.date_of_birth)
					datas.append('phone',this.individu.phone)
					datas.append('occupation',this.individu.occupation)
					datas.append('address',this.individu.address)
					datas.append('place_of_birth',this.individu.place_of_birth)
					datas.append('nationality',this.individu.nationality)
					datas.append('marital_status',this.individu.marital_status)

					// Les 3 valeurs suivantes sont des tableaux
					//datas.append('contact_persons', JSON.stringify(this.individu.contact_persons.items))

					// Traitement manuel du tableau contact_persons
				    const contactPersonsArray = this.individu.contact_persons.items.map(item => {
				        return {
				            country: item.country,
				            lastname: item.lastname,
				            firstname: item.firstname,
				            address: item.address,
				            phone: item.phone
				        };
				    });

				    // Ajout du tableau au FormData
				    for (let i = 0; i < contactPersonsArray.length; i++) {
				        const person = contactPersonsArray[i];
				        for (const key in person) {
				            if (person.hasOwnProperty(key)) {
				                datas.append(`contact_persons[${i}][${key}]`, person[key]);
				            }
				        }
				    }

					//datas.append('parents', JSON.stringify(this.individu.parents))

					// Traitement manuel du tableau parents
				    const parentsData = this.individu.parents;

				    for (const role in parentsData) {
				        if (parentsData.hasOwnProperty(role)) {
				        	//console.log(role, parentsData[role])
				            datas.append(`parents[${role}]`, parentsData[role]);
				        }
				    }

				    //return
					//datas.append('dependents', JSON.stringify(this.individu.dependents.items))

					// Traitement manuel du tableau dependents
				    const dependentsArray = this.individu.dependents.items.map(item => {
				        return {
				            lastname: item.lastname,
				            firstname: item.firstname,
				            date_of_birth: item.date_of_birth,
				            place_of_birth: item.place_of_birth
				        };
				    });

				    // Ajout du tableau au FormData
				    for (let i = 0; i < dependentsArray.length; i++) {
				        const person = dependentsArray[i];
				        for (const key in person) {
				            if (person.hasOwnProperty(key)) {
				                datas.append(`dependents[${i}][${key}]`, person[key]);
				            }
				        }
				    }

					// Traitement manuel du tableau marginal_notes
				    const marginal_notesArray = this.individu.marginal_notes.items.map(item => {
				        return {
				            lastname: item.lastname,
				            firstname: item.firstname,
				            date_of_birth: item.date_of_birth,
				            place_of_birth: item.place_of_birth
				        };
				    });

				    // Ajout du tableau au FormData
				    for (let i = 0; i < marginal_notesArray.length; i++) {
				        const person = marginal_notesArray[i];
				        for (const key in person) {
				            if (person.hasOwnProperty(key)) {
				                datas.append(`marginal_notes[${i}][${key}]`, person[key]);
				            }
				        }
				    }

				} else { // préparation des données à envoyer sans formData
					datas.external_reference = this.individu.external_reference
					datas.email = this.individu.email
					datas.gender = this.individu.gender
					datas.lastname = this.individu.lastname
					datas.firstname = this.individu.firstname
					datas.date_of_birth = this.individu.date_of_birth
					datas.phone = this.individu.phone
					datas.occupation = this.individu.occupation
					datas.address = this.individu.address
					datas.place_of_birth = this.individu.place_of_birth
					datas.nationality = this.individu.nationality
					datas.marital_status = this.individu.marital_status

					// Les 3 valeurs suivantes sont des tableaux
					datas.contact_persons = this.individu.contact_persons.items
					datas.parents = this.individu.parents
					datas.dependents = this.individu.dependents.items
					datas.marginal_notes = this.individu.marginal_notes.items

				}

				//console.log('Datas content:', Array.from(datas.entries()));
				//return 0



				// envoi de la requête
				axios({
					method: 'POST',
					headers: (isNew) ? this.$root.authHeaderFormData() : this.$root.authHeader(), // Utiliser le bon header, avec ou sans multipart/form-data
					url: requestUrl,
					data: datas
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (isNew) ? 'créé' : 'modifié'

						if(isNew)
							this.$swal("Succès!", "L'enregistrement a bien été effectué.", 'success')

						else{
							this.$swal({
	                            title: 'Modification effectuée',
	                            text: 'Que voulez-vous faire maintenant ?',
	                            icon: 'success',
	                            showCancelButton: true,
	                            confirmButtonText: 'Afficher la demande',
	                            cancelButtonText: 'Liste des demandes',
	                            reverseButtons: true
	                        }).then((result) => {
	                            if (result.value) {
	                                document.location.href = this.$root.redirectUri +'/immatriculation/'+ this.uid
	                            } else {
	                                document.location.href = this.$root.redirectUri +'/immatriculations'
	                            }
	                        })
						}

                        this.resetForm()

					}else{
						this.$swal('Oups !', r.data.errors[0], 'error')
					}
				})
				.catch(function (e) {
					//console.log(e)
					//console.log(vm)
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
				
			}
		},
		getPerson () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'app/person/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let p = r.data.data

					this.individu = p
					let d = this.individu.date_of_birth.date.split(' ')
					this.individu.date_of_birth = d[0]

					if(this.individu.dependents.items.length == 0){
						this.individu.dependents.items.push({
			        		lastname: null,
			        		firstname: null,
			        		date_of_birth: null,
			        		place_of_birth: null,
		        		})
					} else{
						this.countFamilyMembers = this.individu.dependents.items.length

						let t = []

					    this.individu.dependents.items.forEach(dependent => {
						    let d = dependent.date_of_birth.date.split(' ')
						    t.push({
					            id: dependent.id,
					            lastname: dependent.lastname,
					            firstname: dependent.firstname,
					            date_of_birth: d[0],
					            place_of_birth: dependent.place_of_birth
					        })
						});

					    this.individu.dependents.items = t
					} 

					if(this.individu.marginal_notes.items.length > 0){
						//this.countFamilyMembers = this.individu.marginal_notes.items.length

						let t = []

					    this.individu.marginal_notes.items.forEach(note => {
						    let d = note.event_date.date.split(' ')
						    t.push({
					            id: note.id,
					            event: note.event,
					            event_date: d[0],
					            spouse_name: note.spouse_name
					        })
						});

					    this.individu.marginal_notes.items = t
					} 

					if(this.individu.contact_persons.items.length == 0){
						this.individu.contact_persons = {
					        "items": [
					        	{
					        		country: 'fr',
					        		lastname: null,
					        		firstname: null,
					        		address: null,
					        		phone: null
					        	},
					        	{
					        		country: 'ci',
					        		lastname: null,
					        		firstname: null,
					        		address: null,
					        		phone: null
					        	}
					        ]
					    }
					}

					this.$swal.close()
					localStorage.setItem('individu', JSON.stringify(this.individu))
				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				//console.log(e)
				//console.log(vm)
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		//this.getRoles()
		this.updateLocalIndividu()
		//console.log(this.individu)
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}


	}
}
</script>
