<template>
	<section class="main-container">
		<h1>Tous les rôles</h1>

		<div class="row row-eq-height">
			<div class="col-6"
				v-for="r in roles"
				:key="r.id"
			>
				<div
					class="card"
				>
					<div class="card-header">
						<h5 class="card-title">{{r.name}}</h5>
					</div>
					<div class="card-body">
						<p>{{r.description}}</p>
						<div>
							<b>Permissions : </b>
							<ul>
								<li
									v-for="p in r.permissions.items"
									:key="p.id"
								>{{p.name}}</li>
							</ul>
						</div>
					</div>
					<div class="card-footer">
						<router-link :to="'/roles/edit/'+r.id" class="btn btn-secondary">
							<i class="fa fa-edit"></i>
							Modifier
						</router-link>
						&nbsp;
						<button class="btn btn-outline-secondary" @click.prevent="deleteRole (r.id)">
							<i class="fa fa-trash"></i>
							Supprimer
						</button>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'RolesList',
	components: { },
	data () {
		return {
			token: localStorage.getItem('user'),
			roles: null,
			permissions: []
		}
	},
	methods: {
		getPermissions(){
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'permissions',
				responseType: 'json'

			}).then(r => {

				r.data.permissions.items.forEach(e => {
					this.permissions[e.id] = e.name
				})
			})
			.catch(function () {
				vm.$root.relogin()
			})
		},
		getRoles () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Chargement des rôles...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'roles',
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.roles = r.data.roles.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		deleteRole (id) {
			let vm = this
			this.$swal({
				title: "Suppression de rôle",
				text: 'Confirmez-vous la suppression de ce rôle ?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Oui',
				cancelButtonText: 'Annuler',
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Suppression du rôle en cours...', 'info')
						return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'DELETE',
						headers: this.$root.authHeader(),
						url: 'role/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.$swal("C'est fait.", r.data.message, 'success')
							this.getRoles()

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						vm.$root.relogin()
					})
					axios.interceptors.request.eject(this.interceptor)
				}
			})	
		}
	},
	created () {
		this.getPermissions()
		this.getRoles()
	}
}
</script>
