<template>
    <div class="">
        <span>{{$root.adjustTimezone(data.created_at.date) | moment("DD/MM/YYYY HH:mm:ss")}}</span>
    </div>
</template>
<script>
export default {
    name: "CreatedDateInDatatable",
    methods: {
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    created () {
        //console.log(this.adjustTimezone(this.data.created_at.date))
    },
};
</script>