<template>
	<section class="main-container">
		<h1 v-if="rid">
			<router-link to="/roles" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Modifier un rôle
			<br>
			<small>{{alias}}</small>
		</h1>
		<h1 v-else>Créer un rôle</h1>
		<form>
			<div class="form-group">
				<label for="name">Nom</label>
				<input type="text" class="form-control" id="name" placeholder="Ex.: Agent de saisie VISA" v-model="alias">
			</div>
			<div class="form-group">
				<label for="description">Description</label>
				<textarea class="form-control" id="description" v-model="description"></textarea>
			</div>
			<div class="form-group">
				<label>Sélectionnez les permissions</label>
				<div class="overflow-auto">
					<label
						class="d-block"
						v-for="p in permissions"
						v-bind:key="p.id"
					> 
						<input type="checkbox" class="selectElt" name="selectedPermissions" :value="p.id" @click="addPermission(p.id)" v-if="p.depends" :data-depends="p.depends">

						<input type="checkbox" class="selectElt" name="selectedPermissions" :value="p.id" @click="addPermission(p.id)" v-else>
						{{p.name}}
					</label>
				</div>
			</div>
			<div class="form-group">
				<input type="submit" name="" value="Enregistrer" class="btn btn-primary" @click.prevent="saveRole()">
				
			</div>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
export default {
	name: 'CreateRole',
	components: { },
	data () {
		return {
			permissions: null,
			selectedPermissions: [],
			alias: null,
			description: null,
			rid: null
		}
	},
	methods: {
		checkPermissions(){
			//console.log(this.selectedPermissions)
		},
		getRole () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du rôle...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'role/'+this.rid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let role = r.data.role

					this.alias = role.name
					this.description = role.description

					role.permissions.items.forEach(e => {
						
						if(this.selectedPermissions.indexOf(e.id) == -1){
							this.selectedPermissions.push(e.id)
							document.querySelector('input.selectElt[value="'+e.id+'"]').checked = true
						}
					})

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		addPermission(id){
			let depends = document.querySelector('input[value="'+id+'"]').dataset.depends.split(',')

			let elt = document.querySelector('input.selectElt[value="'+id+'"]')

			if(this.selectedPermissions.indexOf(id) == -1 && elt.checked){
				this.selectedPermissions.push(id)

				depends.forEach(d => {
					if(this.selectedPermissions.indexOf(d) == -1){
						this.selectedPermissions.push(parseInt(d))
						document.querySelector('input[value="'+d+'"]').checked = true
					}
				})
			}
			else{
				this.selectedPermissions.splice(this.selectedPermissions.indexOf(id),1)

				depends.forEach(d => {
					this.selectedPermissions.splice(this.selectedPermissions.indexOf(d),1)
					//document.querySelector('input[value="'+d+'"]').checked = false
				})
			}
			this.checkPermissions()

		},
		getPermissions(){
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'permissions',
				responseType: 'json'

			}).then(r => {

				this.permissions = r.data.permissions.items
			})
			.catch(function () {
				vm.$root.relogin()
			})
		},
		saveRole () {
			let vm = this
			if (this.alias.length < 1) {
				this.$swal('Nom', 'Vous devez donner un  nom au rôle.', 'warning')
				return !1
			}  else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Création du rôle en cours...', 'info')
						return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.rid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.rid == null) ? 'role' : 'role/'+this.rid

				axios({
					method: requestMethod,
					headers: this.$root.authHeader(),
					url: requestUrl,
					data: {
						'name' : this.alias,
						'description' : this.description,
						'permissions' : this.selectedPermissions
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.rid == null) ? 'ajouté' : 'modifié'
						this.$swal('Parfait !', 'Le rôle a bien été '+w+'.', 'success')

						if(this.rid == null){
							this.alias = null
							this.description = null
							this.selectedPermissions = []
						}
						
					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		}
	},
	created () {
		this.getPermissions()
		if(this.$route.params.id){
			this.rid = parseInt(this.$route.params.id)
			this.getRole()
		}
	}
}
</script>
