<template>
	<section class="main-container">
		<div class="d-flex align-items-center justify-content-between sm-hide">
			<h1>Toutes les demandes</h1>

			<a href="/immatriculation/new" class="btn btn-info">
				<i class="fa fa-plus mr-1"></i>
				Ajouter une demande
			</a>
		</div>
		<div class="lg-hide center mb-2">
			<h1 class="mb-0-5">Toutes les demandes</h1>

			<a href="/immatriculation/new" class="btn btn-info">
				<i class="fa fa-plus mr-1"></i>
				Ajouter une demande
			</a>
		</div>

		<form>
			<div class="grid-1-1-1-1 sm-grid-1-1">
				<div class="form-group">
					<label for="number">N° d'immatriculation</label>
					<input type="text" name="number" id="number" class="form-control" v-model="search.number">
				</div>
				<div class="form-group">
					<label for="name">Nom ou Prénoms</label>
					<input type="text" name="name" id="name" class="form-control" v-model="search.name">
				</div>
				<div class="form-group">
					<label for="datemin">Du</label>
					<input type="date" name="datemin" id="datemin" class="form-control" v-model="search.datemin">
				</div>
				<div class="form-group">
					<label for="datemax">Au</label>
					<input type="date" name="datemax" id="datemax" class="form-control" v-model="search.datemax">
				</div>
			</div>
			<div class="grid-1-1-1 sm-grid-1-1">
				<div class="form-group">
					<label for="reference">Référence dossier</label>
					<input type="text" name="reference" id="reference" class="form-control" v-model="search.reference">
				</div>
				<!--div class="form-group col-3">
					<label for="email">Email</label>
					<input type="text" name="email" id="email" class="form-control" v-model="search.email">
				</div-->
				<div class="form-group">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="getPersons()">
						Rechercher
					</button>
				</div>
				<div class="form-group">
					<label>&nbsp;</label>
					<button class="form-control btn btn-outline-primary" @click.prevent="resetSearch()">
						Réinitialiser
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>

		<h5 class="bold"><span class="text-info">{{meta.items.current}}</span> sur <span class="text-info">{{meta.items.total}} demandes</span></h5>

		<div class="d-flex justify-content-lg-end">
			<div>
				<span class="rounded-pastille done"></span> Carte émise
			</div>
			<div>
				<span class="rounded-pastille cancelled"></span> Carte annulée
			</div>
			<div>
				<span class="rounded-pastille"></span> Carte non générée
			</div>
		</div>

		<data-table v-bind="parameters" style="margin-bottom: 100px;" />
	</section>
</template>

<script>

import axios from 'axios'
//import persons from './persons'
import ActionsButtons from '@/components/ImmatActionsButtons'
import CreatedDateInDatatable from '@/components/CreatedDateInDatatable'
import BirthdateInDatatable from '@/components/BirthdateInDatatable'
//import NameInDatatable from '@/components/NameInDatatable'
//import ContactsInDatatable from '@/components/ContactsInDatatable'
export default {
	name: 'List',
	components: { },
	data () {
		return {
			persons: [],
			search: {
				reference: "",
				number: "",
				name: "",
				phone: "",
				email: "",
				datemin: "",
				datemax: ""
			},
			meta: {
			  "page": {
			    "previous": 0,
			    "current": 0,
			    "next": 0,
			    "last": 0
			  },
			  "items": {
			    "current": 0,
			    "total": 0
			  }
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre d'individus par page :entries",
					infoText: ":first à :last sur :total individus",
					infoTextFiltered: ":first à :last sur :filtered (résultats de :total individus)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche contextuelle : ",
					downloadText: "Exporter vers : ",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 25,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				//showPagination: false,
				showDownloadButton: false,
				data: this.persons,
				columns: [
					{
						key: "registration_number",
						title: "N°"
					},
					{
						key: "lastname",
						title: "Nom"
					},
					{
						key: "firstname",
						title: "Prénom"
					},
					{
						key: "birthday",
						title: "Date Nais.",
						component: BirthdateInDatatable
					},
					{
						key: "place_of_birth",
						title: "Lieu nais."
					},
					/*{
						key: "email",
						title: "Contacts",
						component: ContactsInDatatable
					},*/
					{
						key: "created_at.date",
						title: "Date",
						component: CreatedDateInDatatable
					},
					{
						key: "actions",
						sortable: false,
						component: ActionsButtons
					}
				]
			}
		}
    },
	methods: {
		resetSearch(){
			this.search= {
				reference: "",
				number: "",
				name: "",
				phone: "",
				email: "",
				datemin: "",
				datemax: ""
			}
			this.getPersons()
		},
		getPersons () {
			let vm = this

			let datas = ""

			if(this.search.reference != "")
				datas += "reference="+this.search.reference

			if(this.search.number != "")
				datas += "number="+this.search.number

			if(this.search.name != "")
				datas += "&name="+this.search.name

			if(this.search.phone != "")
				datas += "&phone="+this.search.phone

			if(this.search.email != "")
				datas += "&email="+this.search.email

			if(this.search.datemin != "")
				datas += "&datemin="+this.search.datemin

			if(this.search.datemax != "")
				datas += "&datemax="+this.search.datemax

			datas += "&limit=100"

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des individus...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'app/persons?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.persons = r.data.data.items
					this.meta = r.data.data.meta
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		lock(id, action){
			let vm = this
			let q = (action == 'lock') ? 'bloquer' : 'débloquer'
			this.$swal({
				title: "Blocage / Déblocage",
				text: 'Voulez-vous vraiment '+q+' cet utilisateur ?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Oui',
				cancelButtonText: 'Annuler',
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Action en cours...', 'info')
						return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'POST',
						headers: { 'Authorization': 'Bearer ' + this.token },
						url: 'user/toggleEnabled/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.$swal("C'est fait.", r.data.message, 'success')
							this.getUsers()

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						localStorage.removeItem('user')
						localStorage.removeItem('_rt')
					})
					axios.interceptors.request.eject(this.interceptor)
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
		}
    
	},
	created () {
		this.getPersons()
	}
}
</script>
