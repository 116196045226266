<template>
	<section class="main-container">
		<h1>
			<router-link :to="'/immatriculation/'+person.id" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Sa fiche
			</router-link>
			&nbsp;
			Documents
			<i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{person.firstname}} {{person.lastname}}</span>
		</h1>

		<div class="aic_popup hide">
			<div class="center">
				<div id="viewImage" v-if="popupFor == 'image'">
					<img class="document" :src="docu" alt="Thumbnail" />
				</div>
				<div id="viewDoc" v-if="popupFor == 'doc'">
					<object class="document" :data="docu" type="application/pdf">
			          <embed :src="docu" type="application/pdf" />
			        </object>
				</div>
				<button class="btn btn-outline-secondary topup" @click.prevent="closePopup()">
					<i class="fa fa-remove"></i>
				</button>
			</div>
		</div>

		<div class="row">
			<div class="col-3">
				<img :src="person.photo" class="id_card_photo">
			</div>
			<div class="col-9">
				<h5>
					N° <span class="text-primary">{{person.registration_number}}</span>
				</h5>
				<h4>Ajouter un document</h4>
				<br>
				<form method="post" enctype="multipart/form-data">
					<div class="row">
						<div class="col-3 form-group">
							<label for="documentType">Type de document</label>
							<br>
							<select class="form-control" v-model="documentType" id="documentType">
								<option value=null>Sélectionnez</option>
								<option value="single-file">Fichier unique</option>
								<option value="certificate-of-ivorian-nationality">Certificat de nationalité ivoirienne</option>
								<option value="cni">Carte nationale d'identité</option>
								<option value="identity-certificate">Attestation d'identité</option>
								<option value="excerpt-from-birth">Extrait d'acte de naissance</option>
								<option value="excerpt-from-supplementary-judgment">Extrait du jugement supplétif</option>
								<option value="excerpt-marriage-certificate">Extrait d'acte de mariage</option>
								<option value="photocopy-of-family-record-book">Photocopie de livret de famille</option>
								<option value="marriage-certificate">Certificat de mariage</option>
								<option value="residence-permit">Carte de séjour</option>
								<option value="proof-of-residence">Justificatif de résidence</option>
								<option value="passport-photocopy">Photocopie du passeport</option>
							</select>
						</div>
						<div class="col-6 form-group">
							<label for="documentType">Sélectionnez le document</label>
							<br>
							<input type="file" id="document" class="form-control">
						</div>
						<div class="col-3 form-group">
							<label for="expireDate">Date d'expiration</label>
							<br>
							<input type="date" id="expireDate" class="form-control" v-model="expire_at">
						</div>
					</div>
					<br>
					<div class="form-group">
						<a href="" class="btn btn-primary" @click.prevent="uploadDocument()">
							<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
							Enregistrer
						</a>
					</div>
				</form>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-12">
				<div class="card border-info">
					<div class="card-header bg-info">
						<h4 class="text-white">Documents fournis</h4>
					</div>
					<div class="card-body">
						<div class="card-body text-dark row" v-if="person.documents && person.documents.items.length > 0">
							<div class="col-lg-3 col-md-3" v-for="(document, index) in person.documents.items" :key="index">
						      	<div class="center">
									<select class="form-control" v-model="document.type">
										<option value="single-file">Fichier unique</option>
										<option value="certificate-of-ivorian-nationality">Certificat de nationalité ivoirienne</option>
										<option value="cni">Carte nationale d'identité</option>
										<option value="identity-certificate">Attestation d'identité</option>
										<option value="excerpt-from-birth">Extrait d'acte de naissance</option>
										<option value="excerpt-from-supplementary-judgment">Extrait du jugement supplétif</option>
										<option value="excerpt-marriage-certificate">Extrait d'acte de mariage</option>
										<option value="photocopy-of-family-record-book">Photocopie de livret de famille</option>
										<option value="marriage-certificate">Certificat de mariage</option>
										<option value="residence-permit">Carte de séjour</option>
										<option value="proof-of-residence">Justificatif de résidence</option>
										<option value="passport-photocopy">Photocopie du passeport</option>
									</select>
							        <object class="document-thumbnail pointer" v-if="$root.isPDF(document.document)" :data="document.document" type="application/pdf" @click="openPopup('doc')">
							          <embed :src="document.document" type="application/pdf" />
							        </object>
							        <img class="document-thumbnail pointer" v-else-if="$root.isImage(document.document)" :src="document.document" alt="Thumbnail" @click="openPopup('image')" />
							        <div v-else class="document-thumbnail">
							          <!-- Show a fallback image or icon for unsupported formats -->
							          <img src="fallback-image.jpg" alt="Fallback Thumbnail" />
							        </div>
							        <button class="btn btn-info" @click="viewElt('doc', document.document)">
										<i class="fa fa-eye mr-1"></i> Voir
							        </button>
							        <button class="btn btn-secondary" @click="downloadDocument(document.document)">
										<i class="fa fa-download"></i>
							        </button>
							        <button class="btn btn-danger" @click="deleteDocument(document.id)">
										<i class="fa fa-trash"></i>
							        </button>
						      	</div>
						    </div>
						</div>
						<div class="alert alert-danger" role="alert" v-else>
							Aucun document enregistré.
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Documents',
	components: { },
	data () {
		return {
			uid: null,
			person: null,
			documentType: null,
			documents: null,
			popupFor: 'photo',
			docu: null,
			expire_at: null,
		}
	},
	methods: {
		closePopup(){
			document.querySelector('.aic_popup').classList.add("hide")
		},
		openPopup(t){
			document.querySelector('.aic_popup').classList.remove("hide")
			this.popupFor = t
		},
		viewElt(t, d){
			this.docu = d
			this.openPopup(t)
		},
		uploadDocument () {
			let vm = this
			let fichier = document.querySelector('#document').files[0]

			if (!fichier) {
				this.$swal('Nom', "Vous devez sélectionner un fichier.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Téléversement du document en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let datas = new FormData()

				datas.append('document', fichier)
				datas.append('type', this.documentType)

				if(this.expire_at != null)
					datas.append('expire_at', this.expire_at)
				
				axios({
					method: 'POST',
					headers: this.$root.authHeaderFormData(),
					url: '/app/person/document/' + this.uid,
					data: datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						//let w = (this.uid == null) ? 'créé' : 'modifié'
						this.$swal("C'est fait", "Le document a bien été ajouté.", 'success')
						//document.location.href = this.$root.redirectUri +'/immatriculation/documents/'+ r.data.person

						this.getPerson(true)

						this.documentType = null
						this.expire_at = null

						/*
						this.$swal({
                            title: 'Le document a bien été ajouté.',
                            text: 'Que voulez-vous faire maintenant ?',
                            type: 'success',
                            showCancelButton: true,
                            cancelButtonText: 'Nouvelle inscription',
                            confirmButtonText: 'Voir la fiche',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                document.location.href = this.$root.redirectUri +'/immatriculation/'+ this.uid
                            } else {
                                document.location.href = this.$root.redirectUri +'/immatriculation/new/'
                            }
                        })
                        */

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
				
			}
		},
		deleteDocument (id) {
			let vm = this

			this.$swal({
                title: 'Suppression de document',
                text: 'Confirmez-vous la suppression du document ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    
                	this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Suppression du document...', 'info')
							return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'DELETE',
						headers: this.$root.authHeader(),
						url: 'app/person/document/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.getPerson(true)

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						//vm.$root.relogin()
					})
					axios.interceptors.request.eject(this.interceptor)

                }
            })
		},
		getPerson (l) {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				if(!l)
					this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'app/person/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.person = r.data.data

					this.$swal.close()

					//this.getDocuments()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		getDocuments () {
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'app/person/documents/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.documents = r.data.documents.items


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				//vm.$root.relogin()
			})
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}
	}
}
</script>
