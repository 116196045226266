<template>
    <div class="">
        <span>{{data.created_at.date | moment("DD/MM/YYYY")}}</span>
    </div>
</template>
<script>
export default {
    name: "CreatedDateInDatatable",
    methods: {
        
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>