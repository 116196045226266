<template>
	<section class="main-container">
		<h1>
			<router-link to="/roles" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Rôle
			<i class="fa fa-angle-double-right"></i>
			{{role.name}}
		</h1>
		<br>
		<div class="container">
			<div class="row">
				<div class="col-6">
					<router-link :to="'/roles/edit/'+role.id" class="btn btn-secondary">
						<i class="fa fa-edit"></i>
						Modifier
					</router-link>
					&nbsp;
					<button class="btn btn-outline-secondary btn-outline-info" @click.prevent="deleteRole(role.id)">
						<i class="fa fa-trash"></i>
						Supprimer
					</button>
					<br><br>
					<div class="card">
						<div class="card-header">
							<h5 class="card-title">Permissions</h5>
						</div>
						<div class="card-body">
							<p>{{role.description}}</p>
							<div>
								<div>
									<div>
										<ul>
											<li
												v-for="p in role.permissions"
												:key="p"
											>{{permissions[p]}}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'RoleDetails',
	components: { },
	data () {
		return {
			token: localStorage.getItem('user'),
			role: null,
			rid: null,
			permissions: []
		}
	},
	methods: {
		getPermissions(){
			let vm = this
			axios({
				method: 'GET',
				headers: { 'Authorization': 'Bearer ' + this.token },
				url: 'permissions',
				responseType: 'json'

			}).then(r => {

				r.data.permissions.items.forEach(e => {
					this.permissions[e.id] = e.name
				})
			})
			.catch(function () {
				vm.$root.relogin()
			})
		},
		getRole () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du compte utilisateur...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: { 'Authorization': 'Bearer ' + this.token },
				url: 'role/'+this.rid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.role = r.data.role
					
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		deleteRole (id) {
			let vm = this
			this.$swal({
				title: "Suppression de rôle",
				text: 'Confirmez-vous la suppression de ce rôle ?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Oui',
				cancelButtonText: 'Annuler',
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Suppression du rôle en cours...', 'info')
						return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'DELETE',
						headers: { 'Authorization': 'Bearer ' + this.token },
						url: 'role/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.$swal("C'est fait.", r.data.message, 'success')
							
							setTimeout(function(){
								document.location.href = '/roles'
							}, 3000)

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						vm.$root.relogin()
					})
					axios.interceptors.request.eject(this.interceptor)
				}
			})	
		}
	},
	created () {
		this.getPermissions()
		if(this.$route.params.id){
			this.rid = parseInt(this.$route.params.id)
			this.getRole()
		}
	}
}
</script>
