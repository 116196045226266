import axios from 'axios'

const state = {
  user: null,
  posts: null,
}

const getters = {

}

const actions = {
  async LogIn({commit}, User) {
    await axios.post('access_token', User)
    await commit('setUser', User.get('username'))
  },
  async LogOut({commit}){
    let user = null
    commit('logout', user)
  }
}

const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations
};
