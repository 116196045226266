<template>
	<section class="main-container">
		<h1>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left mr-1"></i>
				Toutes les demandes
			</router-link>
			&nbsp;
			Fiche d'immatriculation consulaire
			<!--i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{person.firstname}} {{person.lastname}}</span-->
		</h1>

		<!--vue-modaltor :visible="open" @hide="open=false">
			<template #header>
				<!-    add your custom header     ->
				<div>
					<i class="closeicon">x</i>
					<p>add modal title or not</p>
				</div>
			</template>
			<template #body>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
					tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
					quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
					cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
					proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				</p>
			</template>
		</vue-modaltor>
		<button @click="open=true">modal-basic</button-->

		<br><br>
		<div :class="(!overlay) ? 'overlay closed' : 'overlay'"></div>
		<aside :class="(!aside) ? 'closed' : ''">
			<div></div>
		</aside>

		<div class="aic_popup hide">
			<div class="center">
				<div id="for_photo" v-if="popupFor == 'photo'">
					<div class="d-flex">
						
						<div class="button-wrapper mr-4">
							<button class="button btn btn-primary" @click.prevent="instanciateFile('photo')">
								Valider
							</button>
						</div>
						<h4>Ajouter la photo d'identité</h4>
					</div>
					
					<input class="selectFile" type="file" ref="photo" @change="loadImage($event, 'photo')" accept="image/*" capture="camera">
					<button id="btnPhoto" class="button btn btn-outline-primary" @click.prevent="$refs.photo.click()">
						<i class="fa fa-camera mr-1"></i>
						Sélectionner une photo
					</button>
					<br><br>
					<cropper
						ref="photoCropper"
						class="upload-example-cropper"
						:src="photo.src"
						:stencil-props="{
							aspectRatio: 0.78,
						}"
					/>
				</div>
				<div id="viewImage" v-else-if="popupFor == 'image'">
					<img class="document" :src="docu" alt="Thumbnail" />
				</div>
				<div id="viewDoc" v-else-if="popupFor == 'doc'">
					<object class="document" :data="docu" type="application/pdf">
			          <embed :src="docu" type="application/pdf" />
			        </object>
				</div>
				<div id="for_signature" v-else>
					<h4>Ajouter la signature</h4>

					<input class="selectFile" type="file" ref="signature" @change="loadImage($event, 'signature')" accept="image/*">
					<button id="btnSignature" class="button btn btn-outline-primary" @click.prevent="$refs.signature.click()">
						<i class="fa fa-camera mr-1"></i>
						Sélectionner une signature
					</button>
					<br><br>
					<cropper
						ref="signatureCropper"
						class="upload-example-cropper wide"
						:src="signature.src"
						:stencil-props="{
							
						}"
					/>
					<br>
					<div class="button-wrapper">
						<button class="button btn btn-primary" @click.prevent="instanciateFile('signature')">
							Valider
						</button>
					</div>
				</div>
				<button class="btn btn-outline-secondary topup" @click.prevent="closePopup()">
					<i class="fa fa-remove"></i>
				</button>
				<!--button class="btn btn-outline-secondary topup" @click.prevent="closePopup()">Annuler</button-->
			</div>
		</div>

		<div class="row">
			<div class="col-3">
				<div class="">
					<div class="card">
						<div class="card-header">
							PHOTO
						</div>
						<div class="card-body">
							<img :src="photoPreview" alt="" class="id_card_photo" v-if="photoPreview">
							<img :src="person.photo" class="id_card_photo" v-else-if="person.photo">
						</div>
					</div>

					<div class="form-group mt-3 center">
						<label for="photo" @click="openPopup('photo')" class="btn btn-label">
							Modifier la photo
						</label>
					</div>
				</div>
				<br>
				<div class="card">
					<div class="card-header">
						SIGNATURE
					</div>
					<div class="card-body">
						<img :src="signaturePreview" alt="" class="id_card_photo" v-if="signaturePreview">
						<img :src="person.signature" class="id_card_photo" v-else>
					</div>
				</div>
				<div class="form-group center mt-3">
					<label for="signature" @click="openPopup('signature')" class="btn btn-label">
						Modifier la signature
					</label>
				</div>
			</div>
			<div class="col-9">
				<div class="d-flex justify-content-between align-items-center" v-if="!person.is_canceled">
					<a :href="'/immatriculation/edit/'+person.id" class="btn btn-outline-info">
						<i class="fa fa-edit mr-1"></i>
						Modifier les informations
					</a>
					<div class="d-flex justify-content-end">
						<a :href="person.card_url" target="_blank" class="btn btn-info upper mr-1" v-if="person.card_url && person.card_url != '' && person.card_url != null">
							<i class="fa fa-download mr-1"></i>
							Télécharger la carte
						</a>

						<a href="#" class="btn btn-success upper" @click.prevent="generateCard('pdf')">
							<i class="fa fa-cog mr-1"></i>
							Générer la carte
						</a>
					</div>
				</div>
				<div v-else>
					<div class="alert alert-danger">
						Immatriculation annulée
					</div>
				</div>
				<br>
				<h2 class="upper">
					{{person.lastname}} {{person.firstname}}
				</h2>
				<h3>
					N° <span class="text-primary">{{person.registration_number}}</span>
				</h3>
				<div class="d-flex justify-content-between mb-1">
					<h5>
						<div v-if="person.external_reference && person.external_reference != ''">
							Référence dossier <span class="text-secondary">{{person.external_reference}}</span>
						</div>
						<div v-else>
							Référence dossier <span class="text-secondary">Non communiquée</span>
						</div>
					</h5>
					<div v-if="user.email == 'monsieur.yapo@gmail.com' || user.email == 'l.gouesse@diplomatie.gouv.ci'">
						<a href="#" class="btn btn-danger" @click.prevent="cancelCard()" v-if="!person.is_canceled">
							<i class="fa fa-ban mr-1"></i>
							Annuler la carte
						</a>
					</div>
				</div>
				<div class="card border-orange mb-3">
					<div class="card-header bg-orange">
						<h4 class="text-white">Informations personnelles</h4>
					</div>
					<div class="card-body text-dark">
						<div>
							<h5 class="card-title text-primary">Civilité</h5>
							<div>
								<div class="row">
									<div class="col-4">
										<strong>Nom</strong>
										<br>
										<span class="upper">{{person.lastname}}</span>
									</div>
									<div class="col-8">
										<strong>Prénoms</strong>
										<br>
										<span class="upper">{{person.firstname}}</span>
									</div>
								</div>
								<br>
								<div class="row">
									<div class="col-4">
										<strong>Date de naissance</strong>	
										<br>
										<span v-if="person.date_of_birth">{{person.date_of_birth.date | moment("DD/MM/YYYY")}}</span>
									</div>
									<div class="col-4">
										<strong>Lieu de naissance</strong>	
										<br>
										<span class="upper">{{person.place_of_birth}}</span>
									</div>
									<!--div class="col-4">
										<strong>Nationalité</strong>	
										<br>
										<span class="upper">{{person.nationality}}</span>
									</div-->
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Situation de famille</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Situation matrimoniale</strong>
									<br>
									<span class="upper">{{maritals_status[person.marital_status]}}</span>
								</div>
							</div>
							<br>
							<h6>Enfants à charge</h6>
							<br>
							<table v-if="person.dependents && person.dependents.items.length > 0">
								<thead>
									<th>Nom</th>
									<th>Prénoms</th>
									<th>Date de naissance</th>
									<th>Lieu de naissance</th>
									<th></th>
								</thead>
								<tbody>
									<tr v-for="(m, k) in person.dependents.items" :key="k">
										<td><span class="upper">{{m.lastname}}</span></td>
										<td><span class="upper">{{m.firstname}}</span></td>
										<td>
											<span v-if="m.date_of_birth">{{m.date_of_birth.date | moment("DD/MM/YYYY")}}</span>
										</td>
										<td><span class="upper">{{m.place_of_birth}}</span></td>
										<td class="d-flex justify-content-end">
											<button class="btn" @click.prevent="deleteDependent(m.id, m.firstname)">
												<i class="fa fa-trash"></i>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="alert alert-warning" v-else>Aucun enfant à charge</div>
							<br>
							<h6>Mentions marginales</h6>
							<br>
							<table v-if="person.marginal_notes && person.marginal_notes.items.length > 0">
								<thead>
									<th>Événement</th>
									<th>Date de l'événement</th>
									<th>Nom complet du(de la) conjoint(e)</th>
								</thead>
								<tbody>
									<tr v-for="(m, k) in person.marginal_notes.items" :key="k">
										<td><span class="upper">{{events[m.event]}}</span></td>
										<td>
											<span v-if="m.event_date">{{m.event_date.date | moment("DD/MM/YYYY")}}</span>
										</td>
										<td><span class="upper">{{m.spouse_name}}</span></td>
									</tr>
								</tbody>
							</table>
							<div class="alert alert-warning" v-else>Aucune mention marginale</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Contacts</h5>

							<div class="row">
								<div class="col-5">
									<strong>Adresse</strong>
									<br>
									<span class="upper">{{person.address}}</span>
								</div>
								<div class="col-3">
									<strong>Téléphone</strong>
									<br>
									<span>{{person.phone}}</span>
								</div>
								<div class="col-4">
									<strong>Email</strong>
									<br>
									<span class="upper">{{person.email}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Situation socio-professionnelle</h5>
							
							<div class="row">
								<div class="col-12">
									<strong>Profession</strong>
									<br>
									<span class="upper">{{person.occupation}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card border-orange mb-3">
					<div class="card-header bg-orange">
						<h3 class="text-white">Parents</h3>
					</div>
					<div class="card-body text-dark" v-if="person.parents && person.parents != ''">
						<div>
							<h5 class="card-title text-primary">Père</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Nom</strong>
									<br>
									<span class="upper">{{person.parents.father_full_name}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu de naissance</strong>
									<br>
									<span class="upper">{{person.parents.father_place_of_birth}}</span>
								</div>
								<div class="col-3">
									<strong>Adresse</strong>
									<br>
									<span class="upper">{{person.parents.father_address_in_Ivory_Coast}}</span>
								</div>
								<div class="col-3">
									<strong>Profession</strong>
									<br>
									<span class="upper">{{person.parents.father_occupation}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Mère</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Nom</strong>
									<br>
									<span class="upper">{{person.parents.mother_full_name}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu de naissance</strong>
									<br>
									<span class="upper">{{person.parents.mother_place_of_birth}}</span>
								</div>
								<div class="col-3">
									<strong>Adresse</strong>
									<br>
									<span class="upper">{{person.parents.mother_address_in_Ivory_Coast}}</span>
								</div>
								<div class="col-3">
									<strong>Profession</strong>
									<br>
									<span class="upper">{{person.parents.mother_occupation}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="alert alert-warning" v-else>Aucun parent enregistré</div>
				</div>

				<div class="card border-orange mb-3">
					<div class="card-header bg-orange">
						<h3 class="text-white">Personnes à contacter</h3>
					</div>
					<div class="card-body text-dark" v-if="person.contact_persons && person.contact_persons.items.length > 0" v-for="c in person.contact_persons.items">
						<div v-if="c.country == 'fr'">
							<h5 class="card-title text-primary">En France</h5>
							
							<div class="row">
								<div class="col-2">
									<strong>Nom</strong>
									<br>
									<span class="upper">{{c.lastname}}</span>
								</div>
								<div class="col-3">
									<strong>Prénoms</strong>
									<br>
									<span class="upper">{{c.firstname}}</span>
								</div>
								<div class="col-4">
									<strong>Adresse</strong>
									<br>
									<span class="upper">{{c.address}}</span>
								</div>
								<div class="col-3">
									<strong>Téléphone</strong>
									<br>
									<span>{{c.phone}}</span>
								</div>
							</div>
						</div>
						<br>
						<div v-if="c.country == 'ci'">
							<h5 class="card-title text-primary">En Côte d'Ivoire</h5>
							<div class="row">
								<div class="col-2">
									<strong>Nom</strong>
									<br>
									<span class="upper">{{c.lastname}}</span>
								</div>
								<div class="col-3">
									<strong>Prénoms</strong>
									<br>
									<span class="upper">{{c.firstname}}</span>
								</div>
								<div class="col-4">
									<strong>Adresse</strong>
									<br>
									<span class="upper">{{c.address}}</span>
								</div>
								<div class="col-3">
									<strong>Téléphone</strong>
									<br>
									<span>{{c.phone}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="alert alert-warning" v-else>Aucun contact enregistré</div>
				</div>
			</div>
		</div>
		<div class="">
			<div class="card border-info mb-3">
				<div class="card-header bg-info">
					<h3 class="text-white">
						<i class="fa fa-file-pdf-o mr-1"></i>
						Documents
					</h3>
				</div>
				<div class="d-flex justify-content-end mt-1 mb-1 pd-1" v-if="!person.is_canceled">
					<a :href="'./documents/'+person.id" class="btn btn-info">
						<i class="fa fa-plus mr-1"></i>
						Ajouter un document
					</a>
				</div>
				<div class="card-body text-dark row" v-if="person.documents && person.documents.items.length > 0">
					<div class="col-lg-3 col-md-3" v-for="(document, index) in person.documents.items" :key="index">
				      	<div class="center">
							<select class="form-control" v-model="document.type">
								<option value="single-file">Fichier unique</option>
								<option value="certificate-of-ivorian-nationality">Certificat de nationalité ivoirienne</option>
								<option value="cni">Carte nationale d'identité</option>
								<option value="identity-certificate">Attestation d'identité</option>
								<option value="excerpt-from-birth">Extrait d'acte de naissance</option>
								<option value="excerpt-from-supplementary-judgment">Extrait du jugement supplétif</option>
								<option value="excerpt-marriage-certificate">Extrait d'acte de mariage</option>
								<option value="photocopy-of-family-record-book">Photocopie de livret de famille</option>
								<option value="marriage-certificate">Certificat de mariage</option>
								<option value="residence-permit">Carte de séjour</option>
								<option value="proof-of-residence">Justificatif de résidence</option>
								<option value="passport-photocopy">Photocopie du passeport</option>
							</select>
					        <object class="document-thumbnail" v-if="isPDF(document.document)" :data="document.document" type="application/pdf">
					          <embed :src="document.document" type="application/pdf" />
					        </object>
					        <img class="document-thumbnail" v-else-if="isImage(document.document)" :src="document.document" alt="Thumbnail" />
					        <div v-else class="document-thumbnail">
					          <!-- Show a fallback image or icon for unsupported formats -->
					          <img src="fallback-image.jpg" alt="Fallback Thumbnail" />
					        </div>
					        <button class="btn btn-info" @click="viewElt('doc', document.document)">
								<i class="fa fa-eye mr-1"></i> Voir
					        </button>
					        <button class="btn btn-secondary" @click="downloadDocument(document.document)">
								<i class="fa fa-download"></i>
					        </button>
					        <button class="btn btn-danger" @click="deleteDocument(document.id)">
								<i class="fa fa-trash"></i>
					        </button>
				      	</div>
				    </div>
				</div>
				<div class="card-body" v-else>
					<div class="alert alert-warning">Aucun document fourni</div>
				</div>
			</div>
		</div>
		<div class="">
			<div class="card mb-3">
				<div class="card-header">
					<h3 class="">
						<i class="fa fa-clock-o mr-1"></i>
						Historique
					</h3>
				</div>
				<div class="card-body text-dark row" v-if="person.actions_logs.items.length > 0">
					
					<data-table v-bind="parameters" style="margin-bottom: 100px;" />
				</div>
				<div class="card-body" v-else>
					<div class="alert alert-warning">Aucune action enregistrée</div>
				</div>
			</div>
		</div>
		<br>
		<!--div class="d-flex justify-content-end">
			<a href="#" class="btn btn-success upper" @click.prevent="generateCard('pdf')">Générer la carte</a>
		</div-->

	</section>
</template>

<script>
import axios from 'axios'
// import { VueEditor } from "vue2-editor"
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import LogActionInDatatable from '@/components/LogActionInDatatable'
import LogActionAgent from '@/components/LogActionAgent'
import CreatedDateHourInDatatable from '@/components/CreatedDateHourInDatatable'

// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}
export default {
	name: 'Demande',
	components: { Cropper },
	props: {
		visible: {
			type: Boolean,
			required: false,
			default: false
		},
		resizeWidth:{
			type:Object,
		},
		animationPanel:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		animationParent:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		bgOverlay:{
			type: String,
			required: false,
			default: '#fff'
		},
		bgPanel:{
			type: String,
			required: false,
			default: '#fff'
		},
		defaultWidth:{
			type: String,
			required: false,
			default: '50%'
		},
		closeScroll:{
			type: Boolean,
			required: false,
			default: true
		}
    },
	data () {
		return {
          	user: JSON.parse(localStorage.getItem('user')),
			photo: {
				src: null,
				type: null
			},
			signature: {
				src: null,
				type: null
			},
			popupFor: 'photo',
			docu: null,
			photoPreview: false,
			signaturePreview: null,
			photo_: null,
			signature_: null,
			open: false,
			uid: null,
			person: null,
			documents: null,
			yesOrNo: {'false':'NON', 'true':'OUI'},
			sendMail: false,
			aside: false,
			overlay: false,
			message: {
				'recever': '',
				'email': '',
				'object': '',
				'message': '',
				'attachments': null
			},
			external_reference: null,
			events: {
				'divorced': 'Divorcé(e)',
				'widowed': 'Veuf(ve)',
				'separated': 'Séparé(e)',
				'married': 'Marié(e)',
			},
			maritals_status: {
				'-': '-',
				'null': '-',
				'divorced': 'Divorcé(e)',
				'married': 'Marié(e)',
				'widowed': 'Veuf(ve)',
				'separated': 'Séparé(e)',
				'single': 'Célibataire'
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre d'actions par page :entries",
					infoText: ":first à :last sur :total actions",
					infoTextFiltered: ":first à :last sur :filtered (résultats de :total actions)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche contextuelle : ",
					downloadText: "Exporter vers : ",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 25,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				//showPagination: false,
				showDownloadButton: false,
				data: this.person.actions_logs.items,
				columns: [
					{
						key: "agent",
						title: "Agent",
						component: LogActionAgent
					},
					{
						key: "action",
						title: "Action",
						component: LogActionInDatatable
					},
					{
						key: "created_at.date",
						title: "Date",
						component: CreatedDateHourInDatatable
					}
				]
			}
		}
    },
	methods: {
		closePopup(){
			document.querySelector('.aic_popup').classList.add("hide")
		},
		openPopup(t){
			document.querySelector('.aic_popup').classList.remove("hide")
			this.popupFor = t
			this.reset(t)
		},
		viewElt(t, d){
			this.docu = d
			this.openPopup(t)
		},
	    isPDF(doc) {
	      return doc.toLowerCase().endsWith('.pdf');
	    },
	    isImage(doc) {
	      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
	      const extension = doc.toLowerCase().substring(doc.lastIndexOf('.'));
	      return imageExtensions.includes(extension);
	    },
	    downloadDocument(doc) {
	      // Implement download logic here
	      //console.log('Download:', 'https://api.immat-cgpci.online/storage/documents/'+document);
	       // Créer un élément <a> invisible
	      var lienDeTelechargement = document.createElement('a')
	      lienDeTelechargement.href = doc
	      lienDeTelechargement.target = '_blank'

	      // Ajouter l'attribut 'download' pour indiquer que c'est un téléchargement
	      lienDeTelechargement.download = doc;

	      // Ajouter l'élément à la page
	      document.body.appendChild(lienDeTelechargement);

	      // Simuler un clic sur l'élément pour déclencher le téléchargement
	      lienDeTelechargement.click();

	      // Supprimer l'élément <a> de la page
	      document.body.removeChild(lienDeTelechargement);
	    },
		instanciateFile(t) {
			const { canvas } = (t == 'photo') ? this.$refs.photoCropper.getResult() : this.$refs.signatureCropper.getResult()
			
			if (canvas) {
				let vm = this
				canvas.toBlob(blob => {
					const reader = new FileReader()

					switch(t){
						case('photo'): vm.photo_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.photoPreview = reader.result
								vm.saveData()
								
							};
						break;
						case('signature'): vm.signature_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.signaturePreview = reader.result
								vm.saveData()
								
							};
						break;
					}

				}, 'image/jpeg');

				document.querySelector('.aic_popup').classList.add("hide")
			}
		},
		reset(t) {
			if(t == 'photo'){
				this.photo = {
					src: null,
					type: null
				}
			}
			else if(t == 'signature'){
				this.signature = {
					src: null,
					type: null
				}
			}
		},
		loadImage(event, t) {

			const { files } = event.target

			if (files && files[0]) {

				const blob = URL.createObjectURL(files[0])
				const reader = new FileReader()

				switch(t){
					case('photo'):
						if (this.photo.src) {
							URL.revokeObjectURL(this.photo.src)
						}

						reader.onload = (e) => {

							this.photo = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
					case('signature'):
						if (this.signature.src) {
							URL.revokeObjectURL(this.signature.src)
						}

						reader.onload = (e) => {

							this.signature = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
				}

				reader.readAsArrayBuffer(files[0]);
			}
		},
		getImgData(files) {

			if (files) {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(files);
				fileReader.addEventListener("load", function () {
					return this.result
				});    
			}
		},
		choosePhoto(){
			this.photo_ = document.querySelector('#photo').files[0]
			//console.log(this.getImgData(this.photo_))
		},
		chooseSignature(){
			this.signature_ = document.querySelector('#signature').files[0]
		},
		saveData () {
			let vm = this

			if (this.photo_ != null || this.signature_ != null) {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let datas = new FormData()

				if(this.photo_ != null)
					datas.append('photo', this.photo_)

				if(this.signature_ != null)
					datas.append('signature', this.signature_)
				
				axios({
					method: 'POST',
					headers: this.$root.authHeaderFormData(),
					url: '/app/person/'+this.uid,
					data: datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.$swal("C'est fait", "L'enregistrement a bien été effectué.", 'success')

						this.photo_ = null
						this.signature_ = null

						this.getPerson(true)

					}else{
						this.$swal('Erreur !', r.data.errors[0], 'error')
					}
				})
				.catch(function (e) {
					//console.log(e)
					//console.log(vm)
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
				
			} else {
				alert('non non')
			}
		},
		toggleAside(obj){
			if(!this.aside){
				this.aside = true
				this.overlay = true
			}
			else {
				this.aside = false
				this.overlay = false
			}

			// envoi de message
			if(obj == 'message')
				this.sendMail = true

			else{
				this.sendMail = false
			}
			
		},
		printFile(fileUrl) {
	      // Créez une nouvelle fenêtre du navigateur pour afficher le contenu du fichier.
	      const printWindow = window.open(fileUrl, "_blank");

	      // Attendez que la fenêtre soit chargée, puis lancez l'impression.
	      printWindow.onload = () => {
	        printWindow.print();
	        printWindow.onafterprint = () => {
	          // Fermez la fenêtre après l'impression.
	          printWindow.close();
	        };
	      };
	    },
	    downloadPDF(rawPDFData) {

	      // Convertir les données brutes en un objet Blob
	      const blob = new Blob([rawPDFData], { type: 'application/pdf' });

	      // Créer un objet URL pour le Blob
	      const blobURL = URL.createObjectURL(blob);

	      // Créer un élément <a> pour déclencher le téléchargement
	      const link = document.createElement('a');
	      link.href = blobURL;
	      link.download = 'document.pdf'; // Nom du fichier à télécharger

	      // Ajouter l'élément <a> à la page
	      document.body.appendChild(link);

	      // Simuler un clic sur l'élément <a> pour déclencher le téléchargement
	      link.click();

	      // Supprimer l'élément <a> de la page
	      document.body.removeChild(link);

	      // Révoquer l'URL de l'objet Blob après le téléchargement
	      URL.revokeObjectURL(blobURL);
	    },
		generateCard(_t){
			//let vm = this
			if(!this.person.photo || this.person.photo == ''){
				this.$swal('Info manquante', 'La photo est obligatoire', 'error')

			} else if(!this.person.signature || this.person.signature == ''){
				this.$swal('Info manquante', 'La signature est obligatoire', 'error')
				
			} else {

				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Génération de la carte...', 'info')
						return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'app/card/generate/'+this.uid,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let card = r.data.link

						this.$swal.close()

				      // Créer un élément <a> invisible
				      var lienDeTelechargement = document.createElement('a')
				      lienDeTelechargement.href = card.doc
				      lienDeTelechargement.target = '_blank'

				      // Ajouter l'attribut 'download' pour indiquer que c'est un téléchargement
				      lienDeTelechargement.download = 'carte-consulaire-'+this.person.id;

				      // Ajouter l'élément à la page
				      document.body.appendChild(lienDeTelechargement);

				      // Simuler un clic sur l'élément pour déclencher le téléchargement
				      lienDeTelechargement.click();

				      // Supprimer l'élément <a> de la page
				      document.body.removeChild(lienDeTelechargement);

						let downloadText = (card.type == 'pdf') ? 
							'<div class="col-12 center"> ' +
								'<a href="'+card.doc+'" target="_blank" class="btn btn-primary" download="'+card.doc+'"> ' +
									'Télécharger' +
								'</a> ' +
							'</div> '
							: 
							'<div class="col-6"> ' +
								'<a href="'+card.recto+'" target="_blank" download="'+card.recto+'"> ' +
									'<img src="'+card.recto+'" class="id_card_photo"> <br>' +
									'Télécharger ' +
								'</a> ' +
							'</div> ' +
							'<div class="col-6"> ' +
								'<a href="'+card.verso+'" target="_blank" download="'+card.verso+'"> ' +
									'<img src="'+card.verso+'" class="id_card_photo"> <br>' +
									'Télécharger ' +
								'</a> ' +
							'</div> '

						this.$swal({
							title: '<strong>Carte générée</strong>',
							icon: 'success',
							html:
								//'Vous pouvez télécharger la carte <br> ' +
								'<div class="row mt-2"> ' +
									downloadText +
								'</div>',
							showCloseButton: true,
							showCancelButton: false,
							focusConfirm: false,
						})

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function (e) {
					console.log(e)
					//vm.$root.relogin()
				})

				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getPerson (l) {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				if(!l)
					this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'app/person/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.person = r.data.data
					//console.log(this.person)

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		deleteDocument (id) {
			let vm = this

			this.$swal({
                title: 'Suppression de document',
                text: 'Confirmez-vous la suppression du document ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    
                	this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Suppression du document...', 'info')
							return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'DELETE',
						headers: this.$root.authHeader(),
						url: 'app/person/document/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.getPerson(true)

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						//vm.$root.relogin()
					})
					axios.interceptors.request.eject(this.interceptor)

                }
            })
		},
		getDocuments () {
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/documents/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.documents = r.data.documents.items


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
		},
		async savePodioReference() {
	      try {
	        
	      	axios({
			  method: 'POST',
			  url: 'app/person/'+this.person.id,
			  data: {
			  	'external_reference': this.external_reference
			  },
				headers: this.$root.authHeader(),
			})
		  	.then(r => {
			    if (r.data.state == 'success') {
					this.$swal("C'est fait", "La mise à jour a bien été effectuée.", 'success')
					this.person.external_reference = this.external_reference

						this.getPerson(true)
				}else{
					this.$swal('Erreur !', r.data.errors[0], 'error')
				}
			})
		  	.catch(error => {
			    // Gestion des erreurs ici
			});

	      } catch (error) {
	        console.error('Une erreur s\'est produite lors de l\'envoi des données :', error);
	      }
	    },
      	deleteDependent(id, name) {

	        this.$swal({
	          title: "Suppression de : "+name,
	          text: 'Confirmez-vous la suppression de cet enfant ?',
	          icon: 'warning',
	          showCancelButton: true,
	          confirmButtonText: 'Supprimer',
	          cancelButtonText: 'Annuler',
	          reverseButtons: true
	        }).then((result) => {
	          if (result.value) {
	            this.interceptor = axios.interceptors.request.use((config) => {
	            	this.$swal('Veuillez patienter', 'Suppression en cours...', 'info')

	              return config
	            }, (error) => {
	              return Promise.reject(error)
	            })

	            axios({
				  method: 'DELETE',
				  url: 'app/dependent/'+id,
				  data: {},
					headers: this.$root.authHeader(),
				})
	            .then((response) => {
	              let resp = response.data

	              if(resp.state != 'error'){
	              	//this.success("L'enfant "+name+" a bien été supprimé.")
	              	this.$swal("C'est fait.", "L'enfant "+name+" a bien été supprimé.", 'success')
	                
	                this.getPerson(true)

	                this.$swal.close()

	              }else{

	                this.$swal("Échec de l'opération", "Veuillez réessayer plus tard.", 'error')
	              }

	            })
	            .catch((error) => {
	            	console.log(error)
				      	
	            });
	            axios.interceptors.request.eject(this.interceptor)

	          } 
	        })
	        
	      },
      	cancelCard() {

	        this.$swal({
	          title: "Annulation de carte",
	          html: "Confirmez-vous l'annulation de la carte ? <br> Cette action est irréversible.",
	          icon: 'warning',
	          showCancelButton: true,
	          confirmButtonText: 'Oui',
	          cancelButtonText: 'Non',
	          reverseButtons: true
	        }).then((result) => {
	          if (result.value) {
	            this.interceptor = axios.interceptors.request.use((config) => {
	            	this.$swal('Veuillez patienter', 'Annulation de la carte en cours...', 'info')

	              return config
	            }, (error) => {
	              return Promise.reject(error)
	            })

	            axios({
				  method: 'PUT',
				  url: 'app/card/canceled/'+this.uid,
				  data: {},
					headers: this.$root.authHeader(),
				})
	            .then((response) => {
	              let resp = response.data

	              if(resp.state != 'error'){
	              	//this.success("L'enfant "+name+" a bien été supprimé.")
	              	this.$swal("C'est fait.", "La carte a bien été annulé.", 'success')
	                
	                this.getPerson(true)

	                this.$swal.close()

	              }else{

	                this.$swal("Échec de l'opération", "Veuillez réessayer plus tard.", 'error')
	              }

	            })
	            .catch((error) => {
	            	console.log(error)
				      	
	            });
	            axios.interceptors.request.eject(this.interceptor)

	          } 
	        })
	        
	      },
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}
	},
	destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
}
</script>
