<template>
    <div class="">
        <p v-if="data.action == 'generate_card'">
            Génération de carte
        </p>
        <p v-if="data.action == 'update_person'">
            Modification des données
        </p>
        <p v-if="data.action == 'create_person'">
            Création de la demande
        </p>
        <p v-if="data.action == 'add_person'">
            Création de la demande
        </p>
        <p v-if="data.action == 'update_photo'">
            Modification de la photo
        </p>
        <p v-if="data.action == 'update_signature'">
            Modification de la signature
        </p>
        <p v-if="data.action == 'cancel_card'">
            Immatriculation annulée
        </p>
    </div>
</template>
<script>
export default {
    name: "ActionInDatatable",
    methods: {
        
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    }
};
</script>