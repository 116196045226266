import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import DataTable from "@andresouzaabreu/vue-data-table"
//import "@/bootstrap/dist/css/bootstrap.min.css"
import "@andresouzaabreu/vue-data-table/dist/DataTable.css"

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

import countries from '@/assets/js/pays'

import VueModalTor from "vue-modaltor/dist/vue-modaltor.common";
import "vue-modaltor/dist/vue-modaltor.css";

Vue.use(VueModalTor, {
    bgPanel: "#7957d5"  // add custome options
});

Vue.use(require('vue-moment'))

Vue.component("data-table", DataTable)

Vue.use(VueSweetalert2)

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
//axios.defaults.withCredentials = true
//axios.defaults.baseURL = 'https://api-usa.monsieur-yapo.com/'
axios.defaults.baseURL = 'https://api.cgci-paris.com/'


Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

  document.title = to.meta.title
 
  return next()
})

new Vue({
  store,
  router,
  axios,
  data () {
    const _ENV = (document.location.href.indexOf('8080') != -1) ? 'dev' : 'prod'

    return {
      access_token: null,
      refresh_token: null,
      redirectUri: (_ENV == 'dev') ? 'http://localhost:8080' : 'https://app.cgci-paris.com',
      //redirectUri: 'https://usa.monsieur-yapo.com',
      pays: countries
    }
  },
  methods: {
    frDate(d){
      let t = d.split(' ')
      let j = t[0].split('-')

      return j[2]+'-'+j[1]+'-'+j[0]
    },
    literateDate(date, short){
      
      let months = ['', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
      let months_short = ['', 'Jan.', 'Fév.', 'Mar.', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.']

      let d = date.split('-')

      return (short) ? d[0]+" "+months_short[parseInt(d[1])]+" "+d[2] : d[0]+" "+months[parseInt(d[1])]+" "+d[2]
    },
    sendMail (to, subject, body, attachments) {
      if (to.length < 1) {
        this.$swal('Destinataire', "Vous devez renseigner au moins un destinataire.", 'warning')
        return !1

      } else if (!subject) {
        this.$swal('Objet', "Vous devez préciser l'objet du message.", 'warning')
        return !1

      } else if (!body) {
        this.$swal('Message', "Vous devez saisir le message.", 'warning')
        return !1

      } else {
        this.interceptor = axios.interceptors.request.use((config) => {
          this.$swal('Veuillez patienter', 'Envoi du message...', 'info')
          return config
        }, (error) => {
          return Promise.reject(error)
        })

        if (typeof attachments != 'undefined') {
          // let datas = new FormData()

          let pj = new Array

          // Check for the File API support.
          if (window.File && window.FileReader && window.FileList && window.Blob) {
            for(let y=0; y<attachments.length; y++){
              var f = attachments[0]; // FileList object
              var reader = new FileReader()
              // Closure to capture the file information.
              reader.onload = (function(theFile) {
                return function(e) {
                  var binaryData = e.target.result
                  //Converting Binary Data to base 64
                  var base64String = window.btoa(binaryData)
                  //console.log(base64String)
                  let f = {
                    "Filename": theFile.name,
                    "ContentType": theFile.type,
                    "Base64Content": base64String
                  }
                  
                  pj.push(f)
                };
              })(f);
              // Read in the image file as a data URL.
              reader.readAsBinaryString(f);

            }
            let vm = this
            let vmr = this.$root

            setTimeout(function(){
              
              axios({
                method: 'POST',
                headers: vmr.authHeader(),
                url: 'email/send',
                data: {
                  'to': to,
                  'subject': subject,
                  'attachments': pj,
                  'body': body
                },
                responseType: 'json'
              })
              .then(r => {    
                if (r.data.state == 'success') {
                  vm.$swal("C'est fait", "Le message a bien été envoyé.", 'success')

                }else{
                  vm.$swal('Oups !', r.data.message, 'error')
                }
              })
              
              axios.interceptors.request.eject(vm.interceptor)
            }, 1000)
          } else {
            alert('The File APIs are not fully supported in this browser.');
          }
        } else {
          axios({
            method: 'POST',
            headers: this.$root.authHeader(),
            url: 'email/send',
            data: {
              'to': to,
              'subject': subject,
              'body': body
            },
            responseType: 'json'
          })
          .then(r => {    
            if (r.data.state == 'success') {
              this.$swal("C'est fait", "Le message a bien été envoyé.", 'success')

            }else{
              this.$swal('Oups !', r.data.message, 'error')
            }
          })
          axios.interceptors.request.eject(this.interceptor)
        }
      }
    },
    setToken (atoken, rtoken) {
      let now = Math.floor(Date.now() / 1000)
      let expireIn = 2592000 - 900 // (30 jours) 15min avant l'expiration on commence à renouveler le token
      let expireAt = now + expireIn
      
      this.access_token = atoken
      this.refresh_token = rtoken

      localStorage.setItem('actk', atoken + ';' + expireAt)
      localStorage.setItem('rtk', rtoken + ';' + (expireAt + 900))

      return this.access_token
    },
    getToken () {
      let token = localStorage.getItem('actk')
      if(!token) return 0

      let tk = token.split(';')

      let now = Math.floor(Date.now() / 1000)
      //console.log('get token')
      if (now >= parseInt(tk[1])) { 
        return this.refreshAccessToken() 
      } else { 
        return tk[0] 
      }
    },
    authHeader () {
      return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    },
    authHeaderFormData () {
      return {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.getToken()
      }
    },
    refreshAccessToken(){
      let token = localStorage.getItem('rtk')

      let tk = token.split(';')

      axios({
        method: 'POST',
        url: 'app/access_token',
        data: {
          "refresh_token": tk[0],
          "grant_type": "refresh_token",
          "client_id": "cgpciImmatApi",
          "client_secret": "j9PDnq5CUE8AhjW"
        },
        responseType: 'json'
      })
      .then(r => {    
        if (r.data.access_token) {
          this.setToken (r.data.access_token, r.data.refresh_token)
          this.getToken ()
          //document.location.reload()
          //document.location.href = history.back()
          //return 1
        }
      })
    },
    relogin(){
      localStorage.removeItem('actk')
      localStorage.removeItem('rtk')
      localStorage.removeItem('user')
      document.location.href = '/login'
      /*
      if(!this.isRefreshTokenValid()){
        this.refreshAccessToken()
        setTimeout(function(){
          document.location.reload()
        }, 1000)
      }else{
        localStorage.removeItem('actk')
        localStorage.removeItem('rtk')
        document.location.href = '/login'
      }
      */
    },
    isRefreshTokenValid () {
      let token = localStorage.getItem('rtk')
      if(!token) return 0

      let tk = token.split(';')

      let now = Math.floor(Date.now() / 1000)

      if (now >= parseInt(tk[1])) { return false } else { return true }
    },
    isTokenValid () {
      let token = localStorage.getItem('actk')
      if(!token) return 0

      let tk = token.split(';')

      let now = Math.floor(Date.now() / 1000)

      if (now >= parseInt(tk[1])) { return false } else { return true }
    },
    YmdDate(date) {
      date = (!date) ? new Date() : date

      var yyyy = date.getFullYear().toString()
      var mm = (date.getMonth()+1).toString()
      var dd  = date.getDate().toString()

      console.log(yyyy, mm, dd)

      var mmChars = mm.split('')
      var ddChars = dd.split('')

      return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0])
    },
    enDate(date) {
      date = new Date(date)

      var yyyy = date.getFullYear().toString()
      var mm = (date.getMonth()+1).toString()
      var dd  = date.getDate().toString()

      return yyyy + '-' + mm + '-' + dd

      var mmChars = mm.split('')
      var ddChars = dd.split('')

      return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0])
    },
    isPDF(doc) {
      return doc.toLowerCase().endsWith('.pdf');
    },
    isImage(doc) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
      const extension = doc.toLowerCase().substring(doc.lastIndexOf('.'));
      return imageExtensions.includes(extension);
    },
    downloadDocument(doc) {
      // Implement download logic here
      //console.log('Download:', 'https://api.immat-cgpci.online/storage/documents/'+document);
       // Créer un élément <a> invisible
      var lienDeTelechargement = document.createElement('a')
      lienDeTelechargement.href = doc
      lienDeTelechargement.target = '_blank'

      // Ajouter l'attribut 'download' pour indiquer que c'est un téléchargement
      lienDeTelechargement.download = doc;

      // Ajouter l'élément à la page
      document.body.appendChild(lienDeTelechargement);

      // Simuler un clic sur l'élément pour déclencher le téléchargement
      lienDeTelechargement.click();

      // Supprimer l'élément <a> de la page
      document.body.removeChild(lienDeTelechargement);
    },
    adjustTimezone(dateString) {
        // Convertir la chaîne de date en objet Date (en supposant que la chaîne est en UTC+0)
        let date = new Date(dateString + 'Z'); // 'Z' indique que la chaîne de date est en UTC

        // Récupérer le décalage horaire du client en minutes
        let offset = new Date().getTimezoneOffset();
        //console.log(offset)
        // Calculer le décalage horaire en millisecondes
        let offsetMilliseconds = ((-1) * offset) * 60000;

        // Ajouter le décalage horaire à la date
        date.setTime(date.getTime() + offsetMilliseconds);

        // Formater la date dans le format souhaité
        let formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');

        return formattedDate;
      }
  },
  created(){
    this.refresh_token = localStorage.getItem('rtk')
  },
  render: h => h(App),
}).$mount('#app')
