<template>
	<section class="main-container">
		<h1>Sécurité de mon compte</h1>

		<div class="row">
			<div class="col-6">
				<h5>Modifier mon mot de passe</h5>
				<br>
				<span class="text-danger">Tous les champs sont obligatoires</span>
				<br>
				<form>
					<div class="row">
						<div class="form-group col-6">
							<label for="old_password">Mot de passe actuel</label>
							<input type="password" class="form-control" id="old_password" v-model="security.old_password">
						</div>
						<div class="form-group col-6">
							<label for="new_password">Nouveau mot de passe</label>
							<input type="password" class="form-control" id="new_password" v-model="security.new_password">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-12">
							<label for="confirm_new_password">Confirmez le nouveau mot de passe</label>
							<input type="password" class="form-control" id="confirm_new_password" v-model="security.confirm_new_password">
						</div>
					</div>
					<br>
					<div class="form-group">
						<input type="submit" name="" value="Enregistrer" class="btn btn-primary" @click.prevent="saveAccount()">
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
import axios from "axios"
export default {
	name: 'MyAccount',
	components: { },
	data () {
		return {
			security: {
				old_password: null,
				new_password: null,
				confirm_new_password: null
			}
		}
	},
	methods: {
		saveAccount () {
			let vm = this
			if (this.security.old_password == null) {
				this.$swal('Mot de passe actuel', "Vous devez renseigner votre mot de passe actuel.", 'warning')
				return !1

			} else if (this.security.new_password == null) {
				this.$swal('Nouveau mot de passe', "Vous devez choisir un nouveau mto de passe.", 'warning')
				return !1

			} else if (this.security.confirm_new_password != this.security.new_password) {
				this.$swal('Confirmation du mot de passe', "La confirmation du nouveau mot de passe est incorrecte.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Modifcation de votre mot de passe...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'PUT',
					headers: this.$root.authHeader(),
					url: 'app/password/change',
					data: {
						'new_password' : this.security.new_password,
						'old_password' : this.security.old_password
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.$swal("C'est fait", 'Le mot de passe a bien été mis à jour.', 'success')

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		}
    
	},
	created () {
	}
}
</script>
