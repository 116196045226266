<template>
	<section class="main-container">
		<div class="container d-flex justify-content-center">   
			<div class="card col-md-5 p-4">
				<div class="d-flex justify-content-between">
					<a href="/" class="logo">
			            <img src="/assets/img/logo-consulat-digital-bleu.png" alt="Consulat Digital">
		          	</a>
					<a href="/" class="logo">
			            <img src="/assets/img/armoirie.png" alt="République de Côte d'Ivoire" style="width:60px;">
		          	</a>
				</div>
				<h1 class="d-flex justify-content-center">Connexion</h1> 
				<div id="loginbox" class="mainbox">                    
					<div class="panel panel-info" >
	                    <div class="panel-heading d-flex justify-content-center">
	                        <div class="panel-title">Entrez vos identifiants</div>
	                        
	                    </div>     

	                    <div style="padding-top:15px" class="panel-body" >

	                        <div style="display:none" id="login-alert" class="alert alert-danger col-sm-12"></div>
	                            
	                        <form id="loginform" class="form-horizontal" role="form">
	                                    
	                            <div style="margin-bottom: 25px" class="input-group d-flex align-items-center">
	                                        <span class="input-group-addon">
	                                        	<i class="fa fa-user"></i>
	                                        </span>
	                                        <input id="login-username" type="text" class="form-control" name="username" value="" placeholder="Adresse email" v-model="email">                                        
	                                    </div>
	                                
	                            <div style="margin-bottom: 25px" class="input-group d-flex align-items-center">
	                                    <span class="input-group-addon">
	                                    	<i class="fa fa-lock"></i>
	                                    </span>
	                                    <input id="login-password" type="password" class="form-control" name="password" placeholder="Mot de passe" v-model="password">
	                                </div>
	                            <div style="margin-top:50px" class="form-group">
	                                <!-- Button -->

	                                <div class="d-flex justify-content-end align-items-center">
	                                  <!--a href="#">Mot de passe oublié ?</a-->
	                                  <button id="btn-login" href="#" class="btn btn-primary" @click.prevent="login()">Connexion</button>
	                                </div>
	                            </div>
	                        </form>  
	                    </div>                     
	                </div>  
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
//import yapOauth2 from '@/assets/js/yap-oauth2'
export default {
	name: 'Login',
	components: { },
	data () {
		return {
			email: null,
			password: null,
			user: null
		}
	},
	methods: {
		init(){
			axios({
				method: 'POST',
				url: 'account/init',
				data: {
					'lastname' : 'Yaou',
					'firstname' : 'YAPO',
					'email' : '',
					'password' : '',
					'position' : 'Prestataire',
					'phone' : ''
				},
				responseType: 'json'
			})
			.then(r => {
				//console.log(r)
			})
		},
		login(){
			if (this.email.length < 5) {
				this.$swal('Adresse email', 'Vous devez saisir votre adresse email.', 'warning')
			} else if (this.password == null) {
				this.$swal('Mot de passe', 'Il manque votre mot de passe.', 'warning')
			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Connexion', 'Authentification en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'POST',
					url: 'app/access_token',
					data: {
						"username": this.email,
						"password": this.password,
						"grant_type": "password",
						//"client_id": "adminapp",
						//"client_secret": "Pmkl?@0i"
						"client_id": "cgpciImmatApi",
						"client_secret": "j9PDnq5CUE8AhjW"
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.access_token) {
						this.$root.setToken(r.data.access_token, r.data.refresh_token)

						axios({
							method: 'GET',
							headers: this.$root.authHeader(),
							url: 'app/agent',
							responseType: 'json'
						})
						.then(r => {    

							if (r.data.state == 'success') {
								this.user = r.data.data
								
								localStorage.setItem('user', JSON.stringify(this.user))

								this.$swal.close()
								document.location.href = this.$root.redirectUri

							}else{
								this.$swal('Oups !', r.data.message, 'error')
							}
						})
					}
				})
				.catch(r => {    
					this.$swal("Échec d'authentification !", 'Vérifiez vos identifiants', 'error')
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		}
	},
	created () {
		//this.$root.isRefreshTokenValid()
		//this.init()
		if(localStorage.getItem('actk') && this.$root.isTokenValid())
			document.location.href = history.back()

		else if(localStorage.getItem('actk') && !this.$root.isTokenValid())
			this.$root.refreshAccessToken()
		
	}
}
</script>
