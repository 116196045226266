import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NewImmat from './views/immatriculation/New.vue'
import ImmatList from './views/immatriculation/List.vue'
import ImmatDetails from './views/immatriculation/Details.vue'
import Documents from './views/immatriculation/Documents.vue'
import ImmatDocuments from './views/immatriculation/ImmatDocuments.vue'
import RoleIndex from './views/roles/Index.vue'
import CreateRole from './views/roles/CreateRole.vue'
import RoleDetails from './views/roles/Details.vue'
import Statistiques from './views/immatriculation/Statistiques.vue'
import Login from './views/Login.vue'
import CreateAccount from './views/accounts/CreateAccount.vue'
import AccountIndex from './views/accounts/Index.vue'
import AccountDetails from './views/accounts/Details.vue'
import Account from './views/accounts/MyAccount.vue'
import Security from './views/accounts/Security.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: {
        title: 'Consulat Digital',
        requiresAuth: true
      },
      name: 'Home',
      component: Home
    },
    {
      path: '/immatriculation/new',
      meta: {
        title: 'Nouvelle immatriculation - Consulat Digital',
        requiresAuth: true
      },
      name: 'NewImmat',
      component: NewImmat
    },
    {
      path: '/immatriculation/documents',
      meta: {
        title: 'Gestion des documents - Consulat Digital',
        requiresAuth: true
      },
      name: 'Documents',
      component: Documents
    },
    {
      path: '/immatriculation/:id',
      meta: {
        title: 'Consulter une demande - Consulat Digital',
        requiresAuth: true
      },
      name: 'ImmatDetails',
      component: ImmatDetails
    },
    {
      path: '/immatriculations',
      meta: {
        title: 'Toutes les demandes - Consulat Digital',
        requiresAuth: true
      },
      name: 'ImmatList',
      component: ImmatList
    },
    {
      path: '/immatriculation/edit/:id',
      meta: {
        title: 'Modifier une immatriculation - Consulat Digital',
        requiresAuth: true
      },
      name: 'EditImmat',
      component: NewImmat
    },
    {
      path: '/immatriculation/documents/:id',
      meta: {
        title: 'Gestion de documents - Consulat Digital',
        requiresAuth: true
      },
      name: 'ImmatDocuments',
      component: ImmatDocuments
    },
    {
      path: '/statistics',
      meta: {
        title: 'Statistiques - Consulat Digital',
        requiresAuth: true
      },
      name: 'Statistiques',
      component: Statistiques
    },
    {
      path: '/login',
      meta: {
        title: 'Authentification - Consulat Digital'
      },
      name: 'Login',
      component: Login
    },
    {
      path: '/accounts/new',
      meta: {
        title: 'Créer un compte - Consulat Digital',
        requiresAuth: true
      },
      name: 'CreateAccount',
      component: CreateAccount
    },
    {
      path: '/account/security',
      meta: {
        title: 'Modifier mon mot de passe - Consulat Digital',
        requiresAuth: true
      },
      name: 'Security',
      component: Security
    },
    {
      path: '/accounts/edit/:id',
      meta: {
        title: 'Modifier un compte - Consulat Digital',
        requiresAuth: true
      },
      name: 'EditAccount',
      component: CreateAccount
    },
    {
      path: '/accounts/:id',
      meta: {
        title: 'Consulter un compte - Consulat Digital',
        requiresAuth: true
      },
      name: 'AccountDetails',
      component: AccountDetails
    },
    {
      path: '/accounts',
      meta: {
        title: 'Tous les comptes - Consulat Digital',
        requiresAuth: true
      },
      name: 'AccountIndex',
      component: AccountIndex
    },
    {
      path: '/account',
      meta: {
        title: 'Mon compte - Consulat Digital',
        requiresAuth: true
      },
      name: 'Account',
      component: Account
    },
    {
      path: '/visa/new',
      meta: {
        title: 'Nouvelle demande - New application'
      },
      name: 'visa',
      component: () => import('./views/visa/New.vue')
    },
    {
      path: '/visa/edit/:id',
      meta: {
        title: 'Modifier - Edit'
      },
      name: 'editVisaApplication',
      component: () => import('./views/visa/Edit.vue')
    },
    {
      path: '/visa/all',
      meta: {
        title: 'Toutes les demandes - All applications'
      },
      name: 'visaAll',
      component: () => import('./views/visa/All.vue')
    },
    {
      path: '/visa/application/:id',
      meta: {
        title: 'Demande de visa'
      },
      name: 'visaSingle',
      component: () => import('./views/visa/Single.vue')
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
