<template>
	<section class="main-container">
		<h1>Tous les comptes</h1>

		<table class="table">
			<thead class="thead-dark">
				<tr>
					<th scope="col">Nom & Prénoms</th>
					<th scope="col">Contacts</th>
					<th scope="col">Poste</th>
					<th scope="col">Rôles</th>
					<th scope="col">Actions</th>
				</tr>
			</thead>
			<tbody>
				<tr
					:class="(u.status == false) ? 'text-danger' : ''"
					v-for="u in users"
					:key="u.id"
				>
					<td>{{u.lastname}} {{u.firstname}}</td>
					<td>
						{{u.email}}
						<br>
						{{u.phone}}
					</td>
					<td>{{u.office}}</td>
					<td>
						<ul class="list-group">
							<li
								class="list-group-item"
								v-for="r in u.roles.items"
								:key="r.id"
							>
								<router-link :to="'/roles/'+r.id">{{r.name}}</router-link>
							</li>
						</ul>
					</td>
					<td>
						<router-link :to="'/accounts/'+u.id" class="btn btn-primary">
							<i class="fa fa-eye"></i>
							Consulter
						</router-link>
						&nbsp;
						<router-link :to="'/accounts/edit/'+u.id" class="btn btn-secondary">
							<i class="fa fa-edit"></i>
							Modifier
						</router-link>
						&nbsp;
						<button class="btn btn-outline-secondary btn-outline-danger" @click.prevent="lock(u.id, 'lock')" v-if="u.status == true">
							<i class="fa fa-lock"></i>
							Bloquer
						</button>
						<button class="btn btn-outline-secondary btn-outline-success" @click.prevent="lock(u.id, 'unlock')" v-else>
							<i class="fa fa-unlock"></i>
							Débloquer
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'AccountsList',
	components: { },
	data () {
		return {
			users: []
		}
	},
	methods: {
		getUsers () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des utilisateurs...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'admins',
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.users = r.data.users.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		lock(id, action){
			let vm = this
			let q = (action == 'lock') ? 'bloquer' : 'débloquer'
			this.$swal({
				title: "Blocage / Déblocage",
				text: 'Voulez-vous vraiment '+q+' cet utilisateur ?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Oui',
				cancelButtonText: 'Annuler',
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Action en cours...', 'info')
						return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'POST',
						headers: this.$root.authHeader(),
						url: 'admin/toggleEnabled/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.$swal("C'est fait.", r.data.message, 'success')
							this.getUsers()

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						vm.$root.relogin()
					})
					axios.interceptors.request.eject(this.interceptor)
				}
			})	
		}
    
	},
	created () {
		this.getUsers()
	}
}
</script>
