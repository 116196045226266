<template>
	<section class="main-container">
		<h1>
			<router-link to="/accounts" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Compte
			<i class="fa fa-angle-double-right"></i>
			{{user.firstname}} {{user.lastname}}
		</h1>

		<div class="container">
			<div class="row">
				<div class="col-6">
					<ol class="list-group">
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Service</div>
								{{user.service}}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Poste</div>
								{{user.occupation}}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Adresse email</div>
								{{user.email}}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">N° téléphone</div>
								{{user.phone}}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Pays</div>
								{{user.country}}
							</div>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-start">
							<div class="ms-2 me-auto">
								<div class="fw-bold">Ville</div>
								{{user.city}}
							</div>
						</li>
					</ol>
				</div>
				<div class="col-6">
					<router-link :to="'/accounts/edit/'+user.id" class="btn btn-secondary">
						<i class="fa fa-edit"></i>
						Modifier
					</router-link>
					&nbsp;
					<button class="btn btn-outline-secondary btn-outline-danger" @click.prevent="lock(user.id, 'lock')" v-if="user.status == true">
						<i class="fa fa-lock"></i>
						Bloquer
					</button>
					<button class="btn btn-outline-secondary btn-outline-success" @click.prevent="lock(user.id, 'unlock')" v-else>
						<i class="fa fa-unlock"></i>
						Débloquer
					</button>
					<br><br>
					<div class="card">
						<div class="card-header">Rôles & permissions</div>
						<div class="card-body">
							<div
								v-for="r in user.roles.items"
								:key="r.id"
							>
								<div>
									<div>
										Rôle : <b>{{r.name}}</b>
									</div>
									<div>
										<p>{{r.description}}</p>
										<div>
											<b>Permissions : </b>
											<ul>
												<li
													v-for="p in r.permissions.items"
													:key="p.id"
												>{{p.name}}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'AccountDetails',
	components: { },
	data () {
		return {
			user: null,
			uid: null,
			permissions: []
		}
	},
	methods: {
		getPermissions(){
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'permissions',
				responseType: 'json'

			}).then(r => {

				r.data.permissions.items.forEach(e => {
					this.permissions[e.id] = e.name
				})
			})
			.catch(function () {
				vm.$root.relogin()
			})
		},
		getUser () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du compte utilisateur...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'admin/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.user = r.data.data
					//console.log(r.data)
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		this.getPermissions()
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getUser()
		}
	}
}
</script>
