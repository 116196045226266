<template>
  <div id="app">
    <div class="wrapper d-flex align-items-stretch">
      <nav id="sidebar" :class="(sidebarOn) ? 'on' : ''" v-if="user">
        <div class="custom-menu">
          <button type="button" id="sidebarCollapse" class="btn btn-primary" @click="toggleSidebar()">
            <i class="fa fa-bars"></i>
            <span class="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div class="p-4 pt-5">
          <a href="/" class="logo">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <img src="/assets/img/logo-cgciparis.png" alt="Consulat Digital">
              </div>
            </div>
            <h1 title="Application d'Immatriculation Consulaire">
              <span class="app-name">Consulat Digital</span>
            </h1>

          </a>
          <!--a href="/" class="logo">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <img src="/assets/img/logo-consulat-digital.png" alt="Consulat Digital">
              </div>
              <div>
                <img src="/assets/img/armoirie.png" alt="Armoirie">
              </div>
            </div>
            <h1 title="Application d'Immatriculation Consulaire">
              CG CI PARIS
              <br>
              <!-span class="app-name">Consulat Digital</span->
            </h1>

          </a-->

          <ul class="list-unstyled components mb-5">
            
            <li>CARTES CONSULAIRES</li>
            
            <!--li>
              <router-link to="/immatriculation/new">
                <i class="fa fa-plus"></i>
                Inscrire
              </router-link>
            </li-->
            <li>
              <a href="/immatriculations">
                <i class="fa fa-list"></i>
                Demandes
              </a>
            </li>
            <!--li>
              <router-link to="/immatriculation/print">
                <i class="fa fa-print"></i>
                Imprimer des cartes
              </router-link>
            </li>
            <li>
              <router-link to="/immatriculation/documents">
                <i class="fa fa-file"></i>
                Documents
              </router-link>
            </li>
            <!-li>
              <router-link to="/immatriculation/rdv">
                <i class="fa fa-calendar"></i>
                Rendez-vous
              </router-link>
            </li-->
            <li>
              <a href="/statistics">
                <i class="fa fa-pie-chart"></i>
                Statistiques
              </a>
            </li>  
          </ul>

          <ul class="list-unstyled components mb-5">        
            <li class="app-sidebar__heading">VISA</li>
            <li>
                <router-link to="/visa/new">
                    <i class="metismenu-icon fa fa-plus"></i>
                    Nouvelle demande
                </router-link>
            </li>
            <!--li>
                <router-link to="/visa/all">
                    <i class="metismenu-icon fa fa-folder-open"></i>
                    Demandes de visa
                </router-link>
            </li-->
          </ul>

          <!--ul class="list-unstyled components mb-5">
            
            <li>PERSONNES DE PASSAGE</li>
            
            <li>
              <router-link to="/passage/new">
                <i class="fa fa-plus"></i>
                Enregistrer une personne
              </router-link>
            </li>
            <li>
              <router-link to="/passages">
                <i class="fa fa-list"></i>
                Toutes les personnes
              </router-link>
            </li>
          </ul>

          <ul class="list-unstyled components mb-5">
            
            <li>REGISTRE D'ACTES</li>
            
            <li>
              <router-link to="/act/new">
                <i class="fa fa-plus"></i>
                Enregistrer
              </router-link>
            </li>
            <li>
              <router-link to="/acts">
                <i class="fa fa-list"></i>
                Liste
              </router-link>
            </li>
            <li>
              <router-link to="/acts/stats">
                <i class="fa fa-pie-chart"></i>
                Statistiques
              </router-link>
            </li>
          </ul>

          <ul class="list-unstyled components mb-5">
            
            <li>ASSOCIATIONS</li>
            
            <li>
              <router-link to="/associations/new">
                <i class="fa fa-plus"></i>
                Nouvelle association
              </router-link>
            </li>
            <li>
              <router-link to="/associations">
                <i class="fa fa-list"></i>
                Associations
              </router-link>
            </li>
          </ul>

          <ul class="list-unstyled components mb-5">
            
            <li>SERVICE DÉPÔT</li>
            
            <li>
              <router-link to="/depots/new">
                <i class="fa fa-plus"></i>
                Déposer un objet
              </router-link>
            </li>
            <li>
              <router-link to="/depots">
                <i class="fa fa-list"></i>
                Liste des dépôts
              </router-link>
            </li>
          </ul>

          <ul class="list-unstyled components mb-5">
            
            <li>COMPTES UTILISATEURS</li>
            
            <li>
              <router-link to="/accounts/new">Créer un compte</router-link>
            </li>
            <li>
              <router-link to="/accounts">Tous les comptes</router-link>
            </li>
          </ul>

          <ul class="list-unstyled components mb-5">
            
            <li>RÔLES & PERMISSIONS</li>
            
            <li>
              <router-link to="/roles/new">Créer un rôle</router-link>
            </li>
            <li>
              <router-link to="/roles">Tous les rôles</router-link>
            </li>
          </ul>

          <ul class="list-unstyled components mb-5">
            <li>Messagerie</li>
            <li>
              <router-link to="/messagerie/mails">
                <i class="fa fa-envelope"></i>
                Mails envoyés
              </router-link>
            </li>
            <li>
              <router-link to="/messagerie/sms">
                <i class="fa fa-envelope"></i>
                SMS envoyés
              </router-link>
            </li>
          </ul-->

          <ul class="list-unstyled components mb-5">
            
            <li>MON COMPTE</li>
            
            <li>
              <a href="/account">
                <i class="fa fa-user"></i>
                Mes informations
              </a>
            </li>
            <li>
              <a href="/account/security">
                <i class="fa fa-lock"></i>
                Sécurité
              </a>
            </li>
            <li>
              <a href="#" class="" @click.prevent="logout()">
                <i class="fa fa-sign-out"></i>
                Déconnexion
              </a>
            </li>
          </ul>

          <div class="footer">
            
          </div>

        </div>
      </nav>

        <!-- Page Content  -->
      <div id="content" class="p-4 p-md-5 pt-5" v-if="user">
        <router-view/>
      </div>
      <div id="content" class="p-4 p-md-5 pt-5" style="width: 100%; margin-left: unset;" v-else>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data () {
    return {
      user: localStorage.getItem('user'),
      token: true,
      email: null,
      password: null,
      apiBaseUrl: 'https://api.cgci-paris.com/',
      sidebarOn: false
    }
  },
  components: {
    
  },
  methods: {
    logout(){
      localStorage.removeItem('actk')
      localStorage.removeItem('rtk')
      localStorage.removeItem('user')
      document.location.href = '/login'
      axios({
        method: 'GET',
        headers: this.$root.authHeader(),
        url: 'logout',
        responseType: 'json'
      })
      .then(r => {
        if(r.data.state == 'success'){
          localStorage.removeItem('user')
          localStorage.removeItem('_rt')
          document.location.reload()
        }
      })
      
    },
    toggleSidebar(){
      this.sidebarOn = (this.sidebarOn) ? false : true
    }
  },
  created(){
    
    if(this.$route.meta.requiresAuth){
      if(localStorage.getItem('actk') && !this.$root.isTokenValid()){
        this.$root.refreshAccessToken()
      }
      else if(!this.$root.getToken())
        document.location.href = this.$root.redirectUri + '/login'

    }
    
    /*if(this.$route.meta.requiresAuth)
      if(!this.$root.getToken())
        document.location.href = this.$root.redirectUri + '/login'*/

  },
  updated(){
    /*
    if(this.$route.meta.requiresAuth){
      if(localStorage.getItem('actk') && !this.$root.isTokenValid()){
        this.$root.refreshAccessToken()
      }
      else if(!this.$root.getToken())
        document.location.href = this.$root.redirectUri + '/login'

    }
    */

    //this.$root.getToken()
  }
}
</script>