<template>
	<section class="main-container">
		<h1 v-if="uid">
			<router-link to="/accounts" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Modifier un compte
			<br>
			<small>{{firstname}} {{lastname}}</small>
		</h1>
		<h1 v-else>Créer un compte</h1>

		<div class="alert alert-info">
			Les champs obligatoires sont marqués par *
		</div>

		<form>
			<div class="row">
				<div class="form-group col-6">
					<label for="lastname">Nom *</label>
					<input type="text" class="form-control" id="lastname" placeholder="Nom de l'utilisateur" v-model="lastname">
					<br>
					<label for="firstname">Prénoms *</label>
					<input type="text" class="form-control" id="firstname" placeholder="Prénoms de l'utilisateur" v-model="firstname">
				</div>
				<div class="form-group col-6">
					<label>Sélectionnez les rôles  *</label>
					<div class="overflow-auto">
						<label
							class="d-block"
							v-for="r in roles"
							v-bind:key="r.id"
						> 
							<input type="checkbox" class="selectElt" name="selectedRoles" :value="r.id" @click="addRole(r.id)">
							{{r.name}}
						</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-6">
					<label for="email">Email *</label>
					<input type="email" class="form-control" id="email" placeholder="Email de l'utilisateur" v-model="email">
				</div>
				<div class="form-group col-6">
					<label for="phone">Téléphone</label>
					<input type="phone" class="form-control" id="phone" placeholder="Téléphone de l'utilisateur" v-model="phone">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-6">
					<label for="country">Pays</label>
					<input type="text" class="form-control" id="country" placeholder="Pays de l'utilisateur" v-model="country">
				</div>
				<div class="form-group col-6">
					<label for="city">Ville</label>
					<input type="text" class="form-control" id="city" placeholder="Ville de l'utilisateur" v-model="city">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-4">
					<label for="administrative_id">Matricule *</label>
					<input type="text" class="form-control" id="administrative_id" placeholder="Matricule administratif" v-model="administrative_id">
				</div>
				<div class="form-group col-4">
					<label for="service">Service</label>
					<input type="text" class="form-control" id="service" placeholder="Service de l'utilisateur" v-model="service">
				</div>
				<div class="form-group col-4">
					<label for="office">Poste *</label>
					<input type="text" class="form-control" id="office" placeholder="Poste de l'utilisateur" v-model="office">
				</div>
			</div>
			<div class="form-group">
				<input type="submit" name="" value="Enregistrer" class="btn btn-primary" @click.prevent="saveAccount()">
				
			</div>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
export default {
	name: 'CreateAccount',
	components: { },
	data () {
		return {
			lastname: null,
			firstname: null,
			email: null,
			phone: null,
			country: null,
			city: null,
			administrative_id: null,
			service: null, 
			office: null,
			selectedRoles: [],
			roles: [],
			uid: null
		}
	},
	methods: {
		getRoles () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Chargement des rôles...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'roles',
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.roles = r.data.roles.items

					if(this.$route.params.id){
						this.uid = this.$route.params.id
						this.getUser()
					}

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		addRole(id){
			let elt = document.querySelector('input.selectElt[value="'+id+'"]')

			if(this.selectedRoles.indexOf(id) == -1 && elt.checked){
				this.selectedRoles.push(id)
			}
			else{
				this.selectedRoles.splice(this.selectedRoles.indexOf(id),1)
			}
		},
		saveAccount () {
			let vm = this
			if (this.lastname == null) {
				this.$swal('Nom', "Vous devez saisir le nom de l'utilisateur.", 'warning')
				return !1

			} else if (this.firstname == null) {
				this.$swal('Nom', "Vous devez saisir le prénom de l'utilisateur.", 'warning')
				return !1

			} else if (this.email == null) {
				this.$swal('Nom', "Vous devez saisir l'adresse email de l'utilisateur.", 'warning')
				return !1

			} else if (this.administrative_id == null) {
				this.$swal('Nom', "Vous devez saisir le matricule administratif de l'utilisateur.", 'warning')
				return !1

			} else if (this.office == null) {
				this.$swal('Nom', "Vous devez saisir le poste l'utilisateur.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Création du rôle en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.uid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.uid == null) ? 'admin' : 'admin/'+this.uid

				axios({
					method: requestMethod,
					headers: this.$root.authHeader(),
					url: requestUrl,
					data: {
						'lastname' : this.lastname,
						'firstname' : this.firstname,
						'email' : this.email,
						'phone' : this.phone,
						'country' : this.country,
						'city' : this.city,
						'administrative_id' : this.administrative_id,
						'service' : this.service,
						'office' : this.office,
						'roles' : this.selectedRoles
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.uid == null) ? 'créé' : 'modifié'
						this.$swal("C'est fait", 'Le compte a bien été '+w+'.', 'success')

						if(this.uid == null){
							this.lastname = null
							this.firstname = null
							this.email = null
							this.phone = null
							this.country = null
							this.city = null
							this.administrative_id = null
							this.service = null 
							this.office = null
							this.selectedRoles = []
						}

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getUser () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du compte utilisateur...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'admin/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let u = r.data.data

					this.lastname = u.lastname
					this.firstname = u.firstname
					this.email = u.email
					this.phone = u.phone
					this.country = u.country
					this.city = u.city
					this.administrative_id = u.administrative_id
					this.service = u.service
					this.office = u.office
					
					u.roles.items.forEach(e => {
						//console.log(e)
						if(this.selectedRoles.indexOf(e.id) == -1){
							this.selectedRoles.push(e.id)
							document.querySelector('input.selectElt[value="'+e.id+'"]').checked = true
						}
					})

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		this.getRoles()
	}
}
</script>
