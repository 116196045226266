<template>
    <div class="action-buttons nowrap">
        <button class="btn btn-outline-dark" @click="handleAction('delete')" v-if="user.email == 'monsieur.yapo@gmail.com' || user.email == 'l.gouesse@diplomatie.gouv.ci'">
            <i class="fa fa-trash"></i>
        </button>
        <button class="btn btn-info" @click="handleAction('view')">
            <!--i class="fa fa-eye"></i--> 
            Détails
        </button>
        <!--button class="btn btn-outline-primary" @click="handleAction('edit')">
            <i class="fa fa-edit"></i>
        </button>
        <button class="btn btn-outline-dark" @click="handleAction('delete')">
            <i class="fa fa-trash"></i>
        </button-->
        <span :class="(data.is_canceled) ? 'rounded-pastille cancelled' : (data.card_url != '') ? 'rounded-pastille done' : 'rounded-pastille'"></span>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: "ActionButtons",
      data () {
        return {
          user: JSON.parse(localStorage.getItem('user'))
        }
      },
    methods: {
        deletePerson(id, name) {

            this.$swal({
              title: "Suppression de : "+name,
              html: 'Confirmez-vous la suppression de cet enregistrement ? <br> Cette action est irréversible',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Supprimer',
              cancelButtonText: 'Annuler',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.interceptor = axios.interceptors.request.use((config) => {
                    this.$swal('Veuillez patienter', 'Suppression en cours...', 'info')

                  return config
                }, (error) => {
                  return Promise.reject(error)
                })

                axios({
                  method: 'DELETE',
                  url: 'app/person/'+id,
                  data: {},
                    headers: this.$root.authHeader(),
                })
                .then((response) => {
                  let resp = response.data

                  if(resp.state != 'error'){
                    this.$swal("C'est fait.", name+" a bien été supprimé.", 'success')
                    

                  }else{

                    this.$swal("Échec de l'opération", "Veuillez réessayer plus tard.", 'error')
                  }

                })
                .catch((error) => {
                    console.log(error)
                        
                });
                axios.interceptors.request.eject(this.interceptor)

              } 
            })
            
          },
        handleAction(actionName) {
            //console.log(this.$root.redirectUri, actionName, this.data)
            //this.$store.commit(actionName, this.data)
            switch(actionName){
                case('view'):
                    document.location.href = this.$root.redirectUri +'/immatriculation/'+ this.data.id
                break;
                case('edit'):
                    document.location.href = this.$root.redirectUri +'/immatriculation/edit/'+ this.data.id
                break;
                case('delete'):
                    this.deletePerson(this.data.id, this.data.lastname+' '+this.data.firstname)
                break;
            }
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    created () {
        //this.getPersons()
    },
};
</script>