<template>
	<section class="main-container">
		<h1>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Immatriculations
			</router-link>
			&nbsp;
			<i class="fa fa-file-pdf-o"></i>
			Gestion de documents
		</h1>

		<div class="row">
			<div class="col-9">
				<form>
					<div class="row">
						<div class="form-group col-4">
							<label for="number">N° d'inscription</label>
							<input type="text" name="number" id="number" class="form-control" v-model="search.number">
						</div>
						<div class="form-group col-4">
							<label for="name">ou Nom & Prénoms</label>
							<input type="text" name="name" id="name" class="form-control" v-model="search.name">
						</div>
						<div class="form-group col-4">
							<label>&nbsp;</label>
							<button class="form-control btn btn-primary" @click.prevent="getPersons()">
								Rechercher
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-5">
				<div>
					<h5 v-if="persons.length > 0">{{persons.length}} résultats</h5>
					<ul class="list-group selectorList" v-if="persons.length > 0">
						<li class="list-group-item toSelect" v-for="p in persons" :key="p.id" :data-id="p.id" @click="selectPerson(p.id)" :class="(person == p.id) ? 'on':''">
							<div class="row">
								<div class="col-2">
									<img :src="p.photo" class="id_card_photo thumbnail">
								</div>
								<div class="col-10">
									<span class="bold">{{p.firstname}} {{p.lastname}}</span> <br>
									<span>Né(e) le {{p.birthday | moment("DD/MM/YYYY")}} à {{p.birthplace}}</span> <br>
									<span>N° {{p.registration_number}}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-7" v-if="person != null">
				<h3>Ajouter un document</h3>
				<br>
				<form method="post" enctype="multipart/form-data">
					<div class="row">
						<div class="col-3 form-group">
							<label for="documentType">Type de document</label>
							<br>
							<select class="form-control" v-model="documentType" id="documentType">
								<option value="">Sélectionnez</option>
								<option value="Fichier unique">Fichier unique</option>
								<option value="Certificat de nationalité ivoirienne">Certificat de nationalité ivoirienne</option>
								<option value="Carte nationale d'identité">Carte nationale d'identité</option>
								<option value="Attestation d'identité">Attestation d'identité</option>
								<option value="Extrait d'acte de naissance">Extrait d'acte de naissance</option>
								<option value="Extrait du jugement supplétif">Extrait du jugement supplétif</option>
								<option value="Extrait d'acte de mariage">Extrait d'acte de mariage</option>
								<option value="Photocopie de livret de famille">Photocopie de livret de famille</option>
								<option value="Certificat de mariage">Certificat de mariage</option>
								<option value="Carte de séjour">Carte de séjour</option>
								<option value="Justificatif de résidence">Justificatif de résidence</option>
								<option value="Photocopie du passeport">Photocopie du passeport</option>
							</select>
						</div>
						<div class="col-9 form-group">
							<label for="documentType">Sélectionnez le document</label>
							<br>
							<input type="file" id="document" class="form-control">
						</div>
					</div>
					<br>
					<div class="form-group">
						<a href="" class="btn btn-primary" @click.prevent="uploadDocument()">
							<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
							Enregistrer
						</a>
					</div>
				</form>

				<div class="card">
					<div class="card-header">
						<h4>Liste des documents fournis</h4>
					</div>
					<div class="card-body">
						<ul v-if="documents && documents.length > 0">
							<li v-for="(d, k) in documents" :key="k">
								<strong>{{d.type}}</strong> [<a :href="d.link" class="" target="_blank">Télécharger</a>]
							</li>
						</ul>
						<div class="alert alert-danger" role="alert" v-else>
							Aucun document enregistré.
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'Documents',
	components: { },
	data () {
		return {
			person: null,
			persons: [],
			search: {
				number: "",
				name: ""
			},
			documentType: "Fichier unique",
			documents: null
		}
	},
	methods: {
		selectPerson(_id){
			this.person = _id

			if(this.person){
				this.getDocuments()
			}
		},
		getPersons () {
			let vm = this

			let datas = ""

			if(this.search.number != "")
				datas += "number="+this.search.number

			if(this.search.name != "")
				datas += "&name="+this.search.name

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des individus...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registrations?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.persons = r.data.data.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		getDocuments () {
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/documents/'+this.person,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.documents = r.data.documents.items


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
		},
		uploadDocument () {
			let vm = this
			let fichier = document.querySelector('#document').files[0]

			if (!fichier) {
				this.$swal('Nom', "Vous devez sélectionner un fichier.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Téléversement du document en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let datas = new FormData()

				datas.append('document', fichier)
				datas.append('type', this.documentType)
				
				axios({
					method: 'POST',
					headers: this.$root.authHeaderFormData(),
					url: '/registration/document/' + this.person,
					data: datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.getDocuments()

						this.$swal({
                            title: 'Le document a bien été ajouté.',
                            text: 'Que voulez-vous faire maintenant ?',
                            type: 'success',
                            showCancelButton: true,
                            confirmButtonText: 'Voir la fiche',
                            cancelButtonText: 'Ajouter un document',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                document.location.href = this.$root.redirectUri +'/immatriculation/'+ this.person
                            } else {
                                this.$swal.close()
                            }
                        })

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
				
			}
		}
    
	},
	created () {
	}
}
</script>
